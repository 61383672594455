import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { extent } from "d3-array";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

import { TCatalogEntities } from "types";

import Pipeline from "../Pipeline";
import { default as SideFilter } from "../../Filter";

import TuerenReducer from "./TuerenReducer";
import TuerenIndicator from "./TuerenIndicator";

interface IInfo {
  value: [number, number] | undefined;
  range: [number, number];
}

const Info: React.FC<IInfo> = ({ value, range }): JSX.Element => {
  let id = "common.tueren.span";
  let values = null;
  if (!value) {
    values = { from: range[0], to: range[1] };
  } else if (value[0] === value[1]) {
    id = "common.tueren.plural";
    values = { count: value[0] };
  } else {
    values = { from: value[0], to: value[1] };
  }

  return (
    <Typography sx={{ mb: 2 }} variant="body1" color="secondary">
      <FormattedMessage {...{ id, values }} />
    </Typography>
  );
};

interface IFilter {
  entities: TCatalogEntities;
  value: number[];
  dispatchFilter?: any;
}

export const TuerenFilter: React.FC<IFilter> = ({
  entities: { vehicle },
  value = [],
  dispatchFilter = () => null,
}): JSX.Element | null => {
  const [maximum, setMaximum]: [number | undefined, any] = useState();
  const [minimum, setMinimum]: [number | undefined, any] = useState();
  const [range, setRange]: [[number, number] | undefined, any] = useState();

  React.useEffect(() => {
    if (!vehicle) return;
    const range: [any, any] = extent(
      Object.values(vehicle)
        .map((e: any) => e.tueren)
        .filter((e: any) => !!e)
    );
    setRange(range);
  }, [vehicle, dispatchFilter]);

  React.useEffect(
    () => {
      if (!(vehicle && range)) return;
      if (!value) {
        setMinimum(range[0]);
        setMaximum(range[1]);
      } else {
        setMinimum(value[0]);
        setMaximum(value[1]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, range]
  );

  const handleChange = (element: any, newValue: any) => {
    setMinimum(newValue[0]);
    setMaximum(newValue[1]);
  };

  const submit = (element: any, value: any) => {
    const newValue =
      JSON.stringify(range) === JSON.stringify(value) ? null : value;
    dispatchFilter({
      type: "UPDATE_FILTER",
      payload: { tueren: newValue },
    });
  };

  if (!(minimum && maximum && range)) return null;

  const marks = [
    { value: range[0], label: range[0] },
    { value: range[1], label: range[1] },
  ];

  if (minimum > range[0]) marks.push({ value: minimum, label: minimum });
  if (maximum < range[1] && minimum !== maximum)
    marks.push({ value: maximum, label: maximum });

  const options = {
    min: range[0],
    max: range[1],
    onChange: handleChange,
    onChangeCommitted: submit,
    marks,
  };

  const Component = (
    <Box sx={{ px: 1 }}>
      <Slider value={[minimum, maximum]} {...options} />
    </Box>
  );

  return (
    <SideFilter
      component={Component}
      open={false}
      title={
        <FormattedMessage id="filter.tueren.label" defaultMessage="Türen" />
      }
    />
  );
};

const Container = () => {
  return (
    <Pipeline
      {...{
        defaultValue: null,
        type: "tueren",
        Component: TuerenFilter,
        Reducer: TuerenReducer,
        Indicator: TuerenIndicator,
      }}
    />
  );
};

export default Container;
