import React, { ReactNode, useReducer } from "react";

import catalogData from "fixtures/catalog.json";
import { json2normalized } from "fixtures/helper";

import CatalogReducer from "./CatalogReducer";
import { TCatalog } from "types";

type TCatalogProvider = {
  config: { type: string };
  children: ReactNode;
};

const emptyCatalogStore: TCatalog = {
  entities: {},
  result: {},
};

export const CatalogStore = React.createContext<{
  state: TCatalog;
  dispatch: React.Dispatch<any>;
}>({
  state: emptyCatalogStore,
  dispatch: () => null,
});

const CatalogProvider = ({
  config = { type: "prod" },
  children,
}: TCatalogProvider) => {
  const [state, dispatch] = useReducer(CatalogReducer, emptyCatalogStore);
  React.useEffect(() => {
    getData(config)(dispatch);
  }, [config]);

  return (
    <CatalogStore.Provider value={{ state, dispatch }}>
      {children}
    </CatalogStore.Provider>
  );
};

export default CatalogProvider;

export const getData =
  ({ type }: { type: string }): any =>
  async (dispatch: any) => {
    switch (type) {
      case "prod":
        const response = await fetch(
          `https://${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_API_STAGE}/catalog/${process.env.REACT_APP_SHOP_ID}`
        );
        let data = await response.json();
        if (
          data.hasOwnProperty("vehicles") &&
          !data.hasOwnProperty("vehicle")
        ) {
          data = { ...data, vehicle: data.vehicles };
          delete data.vehicles;
        }

        data.vehicle = data.vehicle.filter((vehicle: any) => !!vehicle.modell);

        const normalizedData = json2normalized(data);
        dispatch({ type: "INIT", payload: normalizedData });
        break;

      case "stage":
      case "develop":
      case "test":
        dispatch({ type: "INIT", payload: json2normalized(catalogData) });
        break;

      default:
        dispatch({ type: "INIT", payload: json2normalized(catalogData) });
    }
  };
