import React, { FC, useState } from "react";
import { IntlProvider } from "react-intl";

import { ConfigurationStore } from "stores";

export const IntlContext = React.createContext<{
  locale: string;
  setLocale: any;
}>({
  locale: "de",
  setLocale: () => null,
});

const getPreferredLocale = (): string => {
  return navigator.language;
};

const getGlobalLocaleFile = async (locale: string) => {
  if (!locale) return false;
  const response = await fetch(
    `${process.env.REACT_APP_CDN_SERVER}/intl/${locale}.json`
  );
  if (response.status !== 200) {
    return false;
  }
  const data = await response.json();
  return data;
};

const getStoreLocaleFile = async (locale: string, storeId: string) => {
  if (!locale) return false;
  const response = await fetch(
    `${process.env.REACT_APP_CDN_SERVER}/${storeId}/intl/${locale}.json`
  );
  if (response.status !== 200) {
    return false;
  }
  const data = await response.json();
  return data;
};

const Intl: FC = ({ children }): JSX.Element | null => {
  const [locale, setLocale] = useState<any>(null);
  const [messages, setMessages] = useState<any>(null);

  const {
    intl: { defaultLocale, allowedLocales },
  }: any = React.useContext(ConfigurationStore);
  const getLocale = (): string =>
    allowedLocales.find((locale: string) => getPreferredLocale() === locale) ||
    defaultLocale;

  React.useEffect(() => {
    const preferredLocale: string = getLocale();
    setLocale(preferredLocale);
  }, []);

  React.useEffect(() => {
    const loadLocales = async () => {
      const shopId = process.env.REACT_APP_SHOP_ID;
      const globalData = await getGlobalLocaleFile(locale);
      const storeData = shopId
        ? await getStoreLocaleFile(locale, shopId)
        : false;
      if (globalData) {
        if (storeData) {
          setMessages({ ...globalData, ...storeData });
        } else {
          setMessages(globalData);
        }
      }
    };

    loadLocales();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  if (!(locale && messages)) return null;

  return (
    <IntlContext.Provider value={{ locale, setLocale }}>
      <IntlProvider
        locale={locale}
        messages={messages}
        onError={(error: any) => {
          const {
            code,
            descriptor: { id, defaultMessage },
          } = error;
          if (process.env.NODE_ENV === "development") {
            if (code === "MISSING_TRANSLATION") {
              console.log(`,"${id}": "${defaultMessage}"`);
            }
          }
          return;
        }}
      >
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};
export default Intl;
