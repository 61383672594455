import React from "react";
import { useIntl } from "react-intl";

import Box from "@mui/material/Box";

import { Tupel } from "components";

import { TVehicle } from "types";

import { vehicle as defaultVehicle } from "fixtures";
import { Typography } from "@mui/material";

interface IDetail {
  vehicle: TVehicle;
  financeRate?: any;
  short?: boolean;
}

const Detail: React.FC<IDetail> = ({
  vehicle = defaultVehicle,
  financeRate,
  short = false,
}): JSX.Element | null => {
  if (!financeRate?.type) return null;
  switch (financeRate.type) {
    case "buy":
      return <Purchase {...{ vehicle, financeRate, short }} />;
    case "credit":
      return <ClassicCarLoan {...{ vehicle, financeRate, short }} />;
    case "balloon":
      return <BalloonFinancing {...{ vehicle, financeRate, short }} />;
    case "leasing":
      return <LeasingMileage {...{ vehicle, financeRate, short }} />;
    default:
      return <></>;
  }
};
const Purchase: React.FC<IDetail> = ({ financeRate, vehicle }): JSX.Element => {
  return (
    <Box>
      <Tupel
        labelId="common.type"
        textId="filter.financial.rateType.options.cashPurchase.label"
      />
      <Tupel labelId="common.kaufpreis" euro={vehicle.preis} />
    </Box>
  );
};

const ClassicCarLoan: React.FC<IDetail> = ({
  financeRate,
  vehicle,
  short = false,
}): JSX.Element => {
  const {
    rate,
    deposit,
    runtime,
    bank,
    borrowingRate,
    apr,
    total,
    netLoanAmount,
  } = financeRate;
  const { formatMessage: t } = useIntl();

  return (
    <Box>
      <Tupel
        labelId="common.type"
        textId="filter.financial.rateType.options.classicCarLoan.label"
      />
      <Tupel label="Fahrzeugpreis" euro={vehicle.preis} />
      <Tupel
        labelId="filter.financial.deposit.title.label"
        force
        euro={deposit}
      />
      <Tupel
        labelId="common.contractRuntime"
        number={runtime}
        unit={t({ id: "common.months" })}
      />
      <Tupel labelId="common.rate" euro={rate} />
      {!short && (
        <>
          <Tupel
            labelId="filter.financial.borrowingRate.label"
            percent={borrowingRate / 100}
          />
          <Tupel label="Effektiver Jahreszins" percent={apr / 100} />
          <Tupel label="Nettodarlehnsbetrag" euro={netLoanAmount - deposit} />
          <Tupel label="Gesamtbetrag" euro={total} />
        </>
      )}
      <Bank bank={bank} />
    </Box>
  );
};

const BalloonFinancing: React.FC<IDetail> = ({
  financeRate,
  vehicle,
  short = false,
}): JSX.Element => {
  const {
    rate,
    deposit,
    runtime,
    bank,
    borrowingRate,
    apr,
    closingRate,
    total,
    netLoanAmount,
    mileage,
    moreKm,
    lessKm,
  } = financeRate;
  const { formatMessage: t } = useIntl();
  return (
    <Box>
      <Tupel
        labelId="common.type"
        textId="filter.financial.rateType.options.balloonFinancing.label"
      />
      <Tupel labelId="common.vehiclePrice" euro={vehicle.preis} />
      <Tupel
        labelId="filter.financial.deposit.title.label"
        force
        euro={deposit}
      />
      <Tupel
        labelId="common.contractRuntime"
        number={runtime}
        unit={t({ id: "common.months" })}
      />
      <Tupel labelId="common.mileage" number={mileage} unit="km" />
      <Tupel labelId="common.rate" euro={rate} />
      {!short && (
        <>
          <Tupel
            labelId="filter.financial.borrowingRate.label"
            percent={borrowingRate / 100}
          />
          <Tupel labelId="filter.financial.apr.label" percent={apr / 100} />
          <Tupel labelId="filter.financial.total.label" euro={total} />
          <Tupel
            labelId="filter.financial.closingRate.label"
            euro={closingRate}
          />
          <Tupel
            labelId="filter.financial.netLoanAmount.label"
            euro={netLoanAmount - deposit}
          />
          <Tupel labelId="filter.financial.moreKm.label" euro={moreKm} />
          <Tupel labelId="filter.financial.lessKm.label" euro={lessKm} />
        </>
      )}
      <Bank bank={bank} />
    </Box>
  );
};

const LeasingMileage: React.FC<IDetail> = ({
  financeRate,
  vehicle,
  short = false,
}): JSX.Element => {
  const {
    rate,
    deposit,
    runtime,
    bank,
    borrowingRate,
    apr,
    total,
    netLoanAmount,
    mileage,
    moreKm,
    lessKm,
  } = financeRate;

  const { formatMessage: t } = useIntl();
  return (
    <Box>
      <Tupel
        labelId="common.type"
        textId="filter.financial.rateType.options.leasingMileage.label"
      />
      <Tupel
        labelId="filter.financial.deposit.title.label"
        force
        euro={deposit}
      />
      <Tupel
        labelId="common.contractRuntime"
        number={runtime}
        unit={t({ id: "common.months" })}
      />
      <Tupel labelId="common.mileage" number={mileage} unit="km" />
      <Tupel labelId="common.rate" euro={rate} />

      {!short && (
        <>
          <Tupel
            labelId="filter.financial.borrowingRate.label"
            percent={borrowingRate / 100}
          />
          <Tupel labelId="filter.financial.apr.label" percent={apr / 100} />
          <Tupel labelId="filter.financial.total.label" euro={total} />
          <Tupel
            labelId="filter.financial.netLoanAmount.label"
            euro={netLoanAmount - deposit}
          />
          <Tupel labelId="filter.financial.moreKm.label" euro={moreKm} />
          <Tupel labelId="filter.financial.lessKm.label" euro={lessKm} />
        </>
      )}
      <Bank bank={bank} />
    </Box>
  );
};

interface IBank {
  bank: any;
}

const Bank: React.FC<IBank> = ({ bank }): JSX.Element => {
  return (
    <Typography variant="caption" paragraph sx={{ mt: 2, mb: 0 }}>
      {bank.name}
      <br />
      {bank.street} {bank.house_number}
      <br />
      {bank.postal_code} {bank.city}
    </Typography>
  );
};

export default Detail;
