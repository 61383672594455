import React from "react";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import { ReactImageTurntable } from "react-image-turntable";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Keyboard } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";

import PanoramaPhotosphereIcon from "@mui/icons-material/PanoramaPhotosphere";
import CloseIcon from "@mui/icons-material/Close";

import { TVehicle } from "types";

import "./gallery.css";

import Typography from "@mui/material/Typography";

const defaultPicture = "https://cdn.carbando.eu/develop/logo512.png";

interface IGallery {
  vehicle: TVehicle;
  paperProps?: any;
  galleryProps?: any;
}

const Gallery: React.FC<IGallery> = ({
  vehicle,
  paperProps = {},
  galleryProps = {},
}): JSX.Element => {
  const [fullscreen, toggleFullscreen] = React.useState(false);
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [swiperInstance, setSwiperInstance] = React.useState<any>(null);
  const [zoomSwiperInstance, setZoomSwiperInstance] = React.useState<any>(null);

  const zoomIn = () => {
    zoomSwiperInstance.slideTo(swiperInstance.clickedIndex, 0);
    toggleFullscreen(true);
  };

  const zoomOut = () => {
    swiperInstance.slideTo(currentSlide, 0);
    toggleFullscreen(false);
  };

  if (!(vehicle && vehicle.pictures) || vehicle.pictures.length === 0)
    return <EmptyGallery {...{ paperProps }} />;
  return (
    <>
      <Paper {...paperProps}>
        <Swiper
          onSwiper={setSwiperInstance}
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          {...galleryProps}
          onClick={() => zoomIn()}
          onSlideChange={(event) => setCurrentSlide(event.activeIndex)}
          initialSlide={currentSlide}
        >
          {vehicle.app360_bilder_count && (
            <SwiperSlide key={`slide-exterior`}>
              <Box
                sx={{
                  display: "block",
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                  "&:hover": { cursor: "zoom-in" },
                }}
              >
                <img
                  src={`${vehicle.app360_base_path}${vehicle.wgnr}_1.jpg`}
                  alt="360° Außenansicht"
                />
                <Typography
                  variant="h6"
                  sx={{
                    position: "absolute",
                    top: "40%",
                    textAlign: "center",
                    width: "100%",
                    background: "#FFFFFF99",
                    py: 1,
                  }}
                >
                  <PanoramaPhotosphereIcon fontSize="large" />
                  <br />
                  360° Außenansicht
                </Typography>
              </Box>
            </SwiperSlide>
          )}
          {vehicle.app360_interior_picture &&
            vehicle.app360_interior_picture.length && (
              <SwiperSlide key={`slide-interior`}>
                <Box
                  sx={{
                    display: "block",
                    width: "100%",
                    objectFit: "contain",
                    height: "100%",
                    "&:hover": { cursor: "zoom-in" },
                  }}
                >
                  <Box
                    sx={{
                      height: "100%",
                      display: "grid",
                      alignContent: "center",
                    }}
                  >
                    <Box sx={{ aspectRatio: "4/3", overflow: "hidden" }}>
                      <img
                        className="spheroid-thumbnail"
                        src={vehicle.app360_interior_picture}
                        alt="360° Innenansicht"
                      />
                    </Box>
                  </Box>
                  <Typography
                    variant="h6"
                    sx={{
                      position: "absolute",
                      top: "40%",
                      textAlign: "center",
                      width: "100%",
                      background: "#FFFFFF99",
                      py: 1,
                    }}
                  >
                    <PanoramaPhotosphereIcon fontSize="large" />
                    <br />
                    360° Innenansicht
                  </Typography>
                </Box>
              </SwiperSlide>
            )}
          {vehicle.pictures.map(({ src, alt = "" }, index: number) => (
            <SwiperSlide key={`slide-${index}`}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  "&:hover": { cursor: "zoom-in" },
                }}
              >
                <img src={src} alt={alt} />
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Paper>
      <Dialog
        keepMounted
        open={fullscreen}
        onClose={() => zoomOut()}
        fullScreen
      >
        <Swiper
          onSwiper={setZoomSwiperInstance}
          modules={[Navigation, Pagination, Keyboard]}
          navigation
          simulateTouch={false}
          pagination={{ clickable: true }}
          slidesPerView={1}
          keyboard={{
            enabled: true,
          }}
          onSlideChange={(event) => setCurrentSlide(event.activeIndex)}
          initialSlide={currentSlide}
        >
          {vehicle.app360_bilder_count && (
            <SwiperSlide key={`slide-exterior`}>
              <Box sx={{ width: "80%" }}>
                <ReactImageTurntable
                  autoRotate={{ disabled: true }}
                  initialImageIndex={0}
                  images={Array.from(
                    Array(vehicle.app360_bilder_count)
                      .keys()
                      .map(
                        (i) =>
                          `${vehicle.app360_base_path}${vehicle.wgnr}_${i + 1}.jpg`
                      )
                  )}
                />
              </Box>
            </SwiperSlide>
          )}
          {vehicle.app360_interior_picture &&
            vehicle.app360_interior_picture.length && (
              <SwiperSlide key={`slide-interior`}>
                <ReactPhotoSphereViewer
                  src={vehicle.app360_interior_picture}
                  height={"100%"}
                  width={"100%"}
                  navbar={false}
                ></ReactPhotoSphereViewer>
              </SwiperSlide>
            )}
          {vehicle.pictures.map(
            (
              {
                src,
                alt,
              }: {
                src: string;
                alt?: string;
              },
              index: number
            ) => (
              <SwiperSlide key={`slide-${index}`}>
                <img src={src} alt={alt} />
              </SwiperSlide>
            )
          )}
        </Swiper>
        <IconButton
          onClick={() => zoomOut()}
          aria-label="close"
          color="secondary"
          size="large"
          sx={{
            position: "absolute",
            top: "1vh",
            right: "1vw",
            zIndex: 101,
          }}
        >
          <Avatar sx={{ border: 1 }} color="primary">
            <CloseIcon />
          </Avatar>
        </IconButton>
      </Dialog>
    </>
  );
};

interface IEmptyGallery {
  paperProps: any;
}
const EmptyGallery: React.FC<IEmptyGallery> = ({ paperProps }): JSX.Element => (
  <Box sx={{ display: { lgDown: "none" } }}>
    <Paper {...paperProps}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          pt: 2,
        }}
      >
        <img src={defaultPicture} alt="" width="200" />
        <Typography variant="h5" sx={{ mt: 2 }}>
          Bilder folgen in Kürze
        </Typography>
      </Box>
    </Paper>
  </Box>
);

export default Gallery;
