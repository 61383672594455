import React from "react";
import { FormattedNumber, useIntl } from "react-intl";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import Stack from "@mui/material/Stack";

import { TVehicle } from "types";

import { Chip, TChip, NChip, UChip, DChip } from "../components";
import { Typography } from "@mui/material";
import { getEnVKV, stringify } from "components/EnVKV/helper";

interface ICard {
  vehicle: TVehicle;
  onClickHandler?: any;
  paperProps?: any;
}

const defaultPicture = "https://cdn.carbando.eu/develop/logo512.png";

const Component: React.FC<ICard> = ({
  vehicle,
  paperProps = {},
  onClickHandler = () => null,
}): JSX.Element => {
  const { formatMessage: t } = useIntl();

  const picture = vehicle.pictures?.length ? vehicle.pictures[0].src : null;
  const {
    kraftstoff,
    erstzulassung,
    mwst_ausweisbar,
    getriebe,
    kw,
    km,
    app360_bilder_count,
  } = vehicle;
  const className = getVehicleClassName(vehicle);
  const title = `${vehicle.modell.marke.name} - ${vehicle.modell.name}`;
  const titleLong = `${vehicle.modell.marke.name} - ${vehicle.modell.name} - ${vehicle.version}`;
  const EnVKV = getEnVKV(vehicle);
  return (
    <Card
      className={"modell " + className}
      square
      title={className}
      sx={{ boxShadow: 5 }}
    >
      <CardActionArea
        onClick={onClickHandler}
        disableRipple
        sx={{
          height: "100%",
          display: "grid",
          justifyContent: "space-between",
        }}
      >
        <Stack
          sx={{ justifyContent: "space-between", height: "100%" }}
          direction="column"
        >
          <Box>
            <Box sx={{ position: "relative" }}>
              <CardMedia
                component="img"
                loading="lazy"
                image={picture || defaultPicture}
                sx={{
                  objectPosition: "top center",
                  aspectRatio: "12/9",
                }}
                {...{ title, alt: titleLong }}
              />
              <div className="image-overlay" />
            </Box>
            <CardHeader
              {...{ title, alt: titleLong }}
              subheader={vehicle.version}
              titleTypographyProps={{ variant: "h5", color: "primary" }}
              subheaderTypographyProps={{
                variant: "body2",
                color: "primary",
                sx: { textOverflow: "ellipsis" },
              }}
            />
            <CardContent sx={{ py: 0 }}>
              <Stack
                direction="row"
                spacing={1}
                sx={{ fontWeight: "bold", flexWrap: "wrap" }}
                useFlexGap
              >
                <UChip value={km} unit="kilometer" />
                <DChip value={erstzulassung} prefix="EZ " />
                <TChip
                  parameter="kraftstoff"
                  value={kraftstoff.id}
                  defaultMessage={kraftstoff.name}
                />
                <TChip
                  parameter="getriebe"
                  value={getriebe?.id}
                  defaultMessage={getriebe?.name}
                />
                <Chip
                  label={
                    kw ? `${kw} kW (${Math.round(kw * 1.35962)} PS)` : null
                  }
                />
                <Chip label={vehicle.metallic ? "metallic" : null} />
                <Chip label={!vehicle.unfallwagen ? "unfallfrei" : null} />
                <Chip
                  label={
                    mwst_ausweisbar
                      ? t({
                          id: "common.mwst_ausweisbar.label",
                          defaultMessage: "MwSt. ausweisbar",
                        })
                      : null
                  }
                />
                <Chip label={app360_bilder_count ? "360°-Ansicht" : null} />
              </Stack>
            </CardContent>
          </Box>
          <CardContent
            sx={{
              height: { lg: "fill-available" },
              display: "grid",
              pb: 0,
            }}
          >
            <Typography
              variant="body2"
              color="primary"
              sx={{ alignSelf: "end" }}
            >
              {EnVKV && stringify(EnVKV.get("summary"))}
            </Typography>
          </CardContent>
          <CardContent>
            <Stack direction="row" sx={{ justifyContent: "space-between" }}>
              <Typography
                variant="h5"
                color="primary"
                sx={{ fontWeight: "900" }}
              >
                <FormattedNumber
                  style="currency"
                  currency="EUR"
                  value={vehicle.preis}
                  maximumFractionDigits={0}
                />
              </Typography>
              {vehicle.rate && (
                <Typography
                  variant="h6"
                  color="primary"
                  sx={{ fontWeight: "900" }}
                >
                  <FormattedNumber
                    style="currency"
                    currency="EUR"
                    value={vehicle.rate}
                    maximumFractionDigits={0}
                  />
                  /m.
                </Typography>
              )}
            </Stack>
          </CardContent>
        </Stack>
      </CardActionArea>
    </Card>
  );
};

const getVehicleClassName = (vehicle: TVehicle): string => {
  const { fzart, km, location, kraftstoff, aufbau } = vehicle;
  const _fzart = `fzart-${fzart.id}`;
  const _aufbau = `aufbau-${aufbau.id}`;
  const _kraftstoff = `kraftstoff-${kraftstoff.id}`;
  const _location = location ? `location-${location.id}` : undefined;
  const _km = km ? `km-${getLighter(km, [80000, 200000, 300000])}` : "";

  return [_fzart, _km, _location, _kraftstoff, _aufbau].join(" ");
};

const getLighter = (value: number, steps: number[]): string => {
  const result = steps.find((step: number) => value <= step);
  return result ? "lt-" + result : "gt-" + steps.pop();
};

export default Component;
