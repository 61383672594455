import React, { ReactNode, useReducer } from "react";

import FilterReducer from "./FilterReducer";
import { TFilter } from "../types";

type TFilterProvider = {
  config: { type: string };
  children: ReactNode;
};

const emptyFilterStore: TFilter = {
  filter: {},
  reset: {},
  order: "empfohlen",
  reducer: new Map(),
  indicator: new Map(),
};

export const FilterStore = React.createContext<{
  state: TFilter;
  dispatch: React.Dispatch<any>;
}>({
  state: emptyFilterStore,
  dispatch: () => null,
});

const FilterProvider = ({
  config = { type: "prod" },
  children,
}: TFilterProvider) => {
  const [state, dispatch] = useReducer(FilterReducer, emptyFilterStore);

  return (
    <FilterStore.Provider value={{ state, dispatch }}>
      {children}
    </FilterStore.Provider>
  );
};

export default FilterProvider;
