import React from "react";
import { useIntl } from "react-intl";

import Box from "@mui/material/Box";

import { TCatalogEntities } from "types";

import Pipeline from "../Pipeline";

import { default as SideFilter } from "../../Filter";
import List from "./List";

import { FarbeAussenReducer } from "./FarbeReducer";
import FarbeIndicator from "./FarbeIndicator";

interface IFilter {
  entities: TCatalogEntities;
  value: number[];
  dispatchFilter?: any;
}

export const FarbeAussenFilter: React.FC<IFilter> = ({
  entities: { farbe: farbeEntities },
  value = [],
  dispatchFilter = () => null,
}): JSX.Element | null => {
  const { formatMessage: t } = useIntl();

  if (!farbeEntities) return null;
  const FarbeIds = Object.values(farbeEntities).map((e) => e.id);

  const setValueHandler = (value: any) => {
    if (value.length === FarbeIds.length) {
      // all variants are selected, so clean up the filter
      dispatchFilter({ type: "UPDATE_FILTER", payload: { farbe_aussen: [] } });
    } else {
      dispatchFilter({
        type: "UPDATE_FILTER",
        payload: { farbe_aussen: value },
      });
    }
  };

  const options = FarbeIds.map((id: number) => ({
    value: id,
    title: t({
      id: `farbe.${id}.label`,
      defaultMessage: farbeEntities[id].name,
    }),
    hex: t({ id: `farbe.${id}.hex` }),
  }));
  const all = {
    title: t({ id: "common.alle", defaultMessage: "Alle" }),
  };

  const Component = (
    <Box sx={{ px: 1 }}>
      <List {...{ value, options, setValueHandler, all }} />
    </Box>
  );
  return (
    <SideFilter
      component={Component}
      open={false}
      title={t({
        id: "filter.farbe_aussen.label",
        defaultMessage: "Außenfarbe",
      })}
    />
  );
};

const Container = () => {
  return (
    <Pipeline
      {...{
        defaultValue: [],
        type: "farbe_aussen",
        Component: FarbeAussenFilter,
        Reducer: FarbeAussenReducer,
        Indicator: FarbeIndicator,
      }}
    />
  );
};

export default Container;
