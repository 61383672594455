import React from "react";

import { FilterStore } from "stores";
import { Sorter } from "../components";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";

import ResetFilter from "./ResetFilter";

const Indicator: React.FC = (): JSX.Element | null => {
  const {
    state: { filter, indicator, order },
    dispatch,
  } = React.useContext(FilterStore);

  const changeSorter = (newSorter: string) => {
    dispatch({
      type: "CHANGE_ORDER",
      payload: newSorter,
    });
  };

  return (
    <Stack
      sx={{
        position: "sticky",
        mb: 1,
        p: 1,
        top: -1,
        backgroundColor: "#FFFFFFEE",
        zIndex: 1,
        minHeight: 50,
        justifyContent: "flex-start",
        alignItems: "center",
      }}
      direction="row"
    >
      {indicator.size > 0 && (
        <>
          <ResetFilter />
          <Box>
            {Array.from(indicator, ([key, f]: [string | number, any]) => (
              <span key={key}>{f({ filter: filter[key], dispatch })}</span>
            ))}
          </Box>
        </>
      )}
      <Box sx={{ ml: "auto" }}>
        <Sorter value={order} changeValueHandler={changeSorter} showEmpfohlen />
      </Box>
    </Stack>
  );
};

export default Indicator;
