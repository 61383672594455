const FilterReducer = (state: any, action: { type: any; payload?: any }) => {
  const { type, payload = {} } = action;
  switch (type) {
    case "INIT":
      return { ...payload };

    case "ADD_REDUCER":
      let newReducer = state.reducer.set(payload.key, payload.value);
      return { ...state, reducer: newReducer };

    case "ADD_INDICATOR":
      if (state.indicator.get(payload.key)) return state;
      const newIndicator = state.indicator.set(payload.key, payload.value);
      return { ...state, indicator: newIndicator };

    case "REMOVE_ID_FROM_FILTER":
      const { filter, id: idToRemove } = payload;

      let newFilter = state.filter[filter].filter(
        (id: any) => id !== idToRemove
      );

      return { ...state, filter: { ...state.filter, [filter]: newFilter } };

    case "UPDATE_FILTER":
      return { ...state, filter: { ...state.filter, ...payload } };

    case "RESET_FILTER":
      return { ...state, filter: state.reset };

    case "INIT_FILTER":
      const subject = Object.keys(payload)[0];
      if (subject in state.filter) return state;
      return {
        ...state,
        filter: { ...state.filter, ...payload.value },
        reset: { ...state.reset, ...payload.reset },
      };
    case "CHANGE_ORDER":
      console.log(payload);
      return {
        ...state,
        order: payload,
      };

    default:
      return state;
  }
};

export default FilterReducer;
