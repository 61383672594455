import React from "react";
import { Helmet } from "react-helmet-async";

import { TVehicle } from "types";
import { ConfigurationStore } from "stores";

interface IHeader {
  vehicle: TVehicle;
}

const Header: React.FC<IHeader> = ({ vehicle }): JSX.Element => {
  const configuration = React.useContext(ConfigurationStore);
  const href = window.location.href;

  const { wgnr, modell, version = "", pictures = [] } = vehicle;

  const preview = pictures && pictures.length > 0 ? pictures[0].src : null;
  const title = configuration.application.title || "carbando catalog";

  return (
    <Helmet>
      <title>{`${title} - ${modell.marke.name} ${modell.name} - ${version} - ${wgnr}`}</title>
      <meta
        property="og:title"
        content={`${title} - ${modell.marke.name} ${modell.name} - ${version} - ${wgnr}`}
      />
      <meta property="og:url" content={href} />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content={title} />
      <meta
        property="og:description"
        content={`${title} - ${modell.marke.name} ${modell.name} - ${version} - ${wgnr}`}
      />
      {preview && <meta property="og:image" content={preview} />}
    </Helmet>
  );
};

export default Header;
