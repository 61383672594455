import React from "react";
import { Helmet } from "react-helmet-async";

import { ConfigurationStore } from "stores";

const Header: React.FC = (): JSX.Element => {
  const configuration = React.useContext(ConfigurationStore);

  const href = window.location.href;
  const title = configuration.application.title || "carbando catalog";

  return (
    <Helmet>
      <title>{title}</title>
      <meta property="og:title" content={title} />
      <meta property="og:url" content={href} />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content={title} />
    </Helmet>
  );
};

export default Header;
