import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSearchParams } from "react-router-dom";

import * as d3Collection from "d3-collection";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import { default as MuiList } from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import List from "../../List";
import Filter from "../../Filter";

import { CatalogStore, FilterStore } from "stores";
import { ConfigurationStore } from "stores";

import MarkeReducer from "../MarkeFilter/MarkeReducer";
import MarkeIndicator from "../MarkeFilter/MarkeIndicator";

import ModellReducer from "../ModellFilter/ModellReducer";
import ModellIndicator from "../ModellFilter/ModellIndicator";
import ModellGruppeIndicator from "./ModellGruppeIndicator";

import { SearchField } from "../AusstattungFilter";

interface iMarkeModellFilter {
  disabled: boolean;
}

const MarkeModellGruppeFilter: React.FC<iMarkeModellFilter> = ({
  disabled = false,
}): JSX.Element | null => {
  const [search, setSearch] = React.useState("");
  const [searchParams] = useSearchParams();
  const configuration = React.useContext(ConfigurationStore);

  const { formatMessage: t } = useIntl();

  const {
    state: {
      filter: {
        marke: markeFilter,
        modell: modellFilter,
        modell_gruppe: modellGruppeFilter,
      },
    },
    dispatch,
  } = React.useContext(FilterStore);

  const {
    state: {
      entities: {
        marke: markeCollection,
        modell: modellCollection,
        modell_gruppe: modellGruppeCollection,
      },
    },
  } = React.useContext(CatalogStore);

  React.useEffect(
    () => {
      const markeParams = searchParams.get("marke");
      const markeValue = markeParams
        ? markeParams.split(",").map((e) => parseInt(e))
        : [];

      const modellParams = searchParams.get("modell");
      const modellValue = modellParams
        ? modellParams.split(",").map((e) => parseInt(e))
        : [];
      const modell_gruppeParams = searchParams.get("modell_gruppe");
      const modell_gruppeValue = modell_gruppeParams
        ? modell_gruppeParams.split(",").map((e) => parseInt(e))
        : [];

      dispatch({
        type: "ADD_REDUCER",
        payload: { key: "marke", value: MarkeReducer },
      });
      dispatch({
        type: "ADD_REDUCER",
        payload: { key: "modell", value: ModellReducer },
      });
      dispatch({
        type: "ADD_INDICATOR",
        payload: { key: "marke", value: MarkeIndicator },
      });
      dispatch({
        type: "ADD_INDICATOR",
        payload: { key: "modell", value: ModellIndicator },
      });
      dispatch({
        type: "ADD_INDICATOR",
        payload: { key: "modell_gruppe", value: ModellGruppeIndicator },
      });
      dispatch({
        type: "INIT_FILTER",
        payload: {
          value: {
            marke: markeValue,
            modell: modellValue,
            modell_gruppe: modell_gruppeValue,
          },
          reset: {
            marke: [],
            modell: [],
            modell_gruppe: [],
          },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  if (
    !(
      markeCollection &&
      modellCollection &&
      modellGruppeCollection &&
      markeFilter &&
      modellFilter &&
      modellGruppeFilter
    )
  )
    return null;

  const toggleModell = (value: any) => {
    const checkedModel =
      value.length > modellFilter.length
        ? value.filter((n: any) => !modellFilter.includes(n))[0]
        : modellFilter.filter((n: any) => !value.includes(n))[0];

    const modellMarke = modellCollection[checkedModel].marke;
    const markeIsChecked = markeFilter.includes(modellMarke);
    const modelsOfMarke = Object.values(modellCollection)
      .filter(({ marke, id }: any) => marke === modellMarke)
      .map(({ id }: any) => id);

    const newFilter = [
      {
        key: "modell",
        value,
      },
    ];

    if (markeIsChecked) {
      const newMarkesFilter = markeFilter.filter(
        (marke: any) => marke !== modellMarke
      );
      newFilter.push({
        key: "marke",
        value: newMarkesFilter,
      });
    } else {
      const allModelsOfMarkeAreChecked = !modelsOfMarke.filter(
        (id) => !value.includes(id)
      ).length;
      if (allModelsOfMarkeAreChecked) {
        toggleMarke(modellMarke);
        return;
      }
    }

    const modellGruppe = modellCollection[checkedModel].modell_gruppe;
    if (modellGruppe) {
      const gruppeIsChecked = modellGruppeFilter.includes(modellGruppe);
      const modelsOfGruppe = Object.values(modellCollection)
        .filter(({ modell_gruppe, id }: any) => modell_gruppe === modellGruppe)
        .map(({ id }: any) => id);

      if (gruppeIsChecked) {
        const newModellGruppeFilter = modellGruppeFilter.filter(
          (modell_gruppe: any) => modell_gruppe !== modellGruppe
        );
        newFilter.push({
          key: "modell_gruppe",
          value: newModellGruppeFilter,
        });
      } else {
        const allModelsOfGroupAreChecked = !modelsOfGruppe.filter(
          (id) => !value.includes(id)
        ).length;
        if (allModelsOfGroupAreChecked) {
          toggleGruppe(modellGruppe);
          return;
        }
      }
    }

    setFilterHandler(newFilter);
  };

  const toggleMarke = (optionValue: number) => {
    const markeIsChecked = markeFilter.includes(optionValue);
    let newChecked;
    if (markeIsChecked) {
      newChecked = markeFilter.filter((id: number) => id !== optionValue);
    } else {
      newChecked = [...markeFilter, optionValue];
    }

    const newModelleFilter = modellFilter.filter(
      (id: number) => modellCollection[id].marke !== optionValue
    );

    const newModellGruppeFilter = modellGruppeFilter.filter(
      (id: number) => modellGruppeCollection[id].marke !== optionValue
    );

    const newFilter = [
      {
        key: "marke",
        value: newChecked,
      },
      {
        key: "modell",
        value: newModelleFilter,
      },
      {
        key: "modell_gruppe",
        value: newModellGruppeFilter,
      },
    ];

    setFilterHandler(newFilter);
  };

  const toggleGruppe = (optionValue: number) => {
    const modellGruppeIsChecked = modellGruppeFilter.includes(optionValue);
    let newChecked;
    let newFilter = [];
    if (modellGruppeIsChecked) {
      newChecked = modellGruppeFilter.filter(
        (id: number) => id !== optionValue
      );
    } else {
      newChecked = [...modellGruppeFilter, optionValue];

      const newMarkeFilter = markeFilter.filter(
        (id: number) => modellGruppeCollection[optionValue].marke !== id
      );
      newFilter.push({
        key: "marke",
        value: newMarkeFilter,
      });

      const newModelleFilter = modellFilter.filter(
        (id: number) => modellCollection[id].modell_gruppe !== optionValue
      );
      newFilter.push({
        key: "modell",
        value: newModelleFilter,
      });
    }
    newFilter.push({
      key: "modell_gruppe",
      value: newChecked,
    });

    setFilterHandler(newFilter);
  };

  const changeSearch = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setSearch(event.target.value);
  };

  const setFilterHandler = (updates: any[]) => {
    updates.map(({ key, value }) => {
      return dispatch({ type: "UPDATE_FILTER", payload: { [key]: value } });
    });
    return true;
  };

  const selectAll = () => {
    setFilterHandler([
      {
        key: "marke",
        value: [],
      },
      {
        key: "modell",
        value: [],
      },
      {
        key: "modell_gruppe",
        value: [],
      },
    ]);
  };
  const favouritesMarke = configuration?.filter?.favourites?.marke || undefined;

  var options = d3Collection
    .nest()
    .key(({ marke }: any) => marke)
    .sortKeys((markeIdA: string, markeIdB: string) => {
      if (favouritesMarke) {
        if (favouritesMarke.includes(parseInt(markeIdA, 10))) {
          if (favouritesMarke.includes(parseInt(markeIdB, 10))) {
            return favouritesMarke.indexOf(parseInt(markeIdA)) <
              favouritesMarke.indexOf(parseInt(markeIdB))
              ? -1
              : 1;
          }
          return -1;
        }
        if (favouritesMarke.includes(parseInt(markeIdB, 10))) {
          return -1;
        }
      }

      return markeCollection[markeIdB].name > markeCollection[markeIdA].name
        ? -1
        : 1;
    })
    .sortValues(({ name: nameA }: any, { name: nameB }: any) => {
      return nameA > nameB ? 1 : -1;
    })
    .entries(Object.values(modellCollection));
  const searchedModells = Object.values(modellCollection)
    .filter(
      ({ name }: any) =>
        search.length > 1 &&
        name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
    )
    .map(({ id, name }: any) => ({ value: id, title: name }));

  const searchedMarkes = Object.values(markeCollection)
    .filter(
      ({ name }: any) =>
        search.length > 1 &&
        name.toLocaleLowerCase().indexOf(search.toLocaleLowerCase()) > -1
    )
    .map(({ id, name }: any) => ({ value: id, title: name }));
  let lastFavourite = false;
  const Component = (
    <Box width={1}>
      <MuiList>
        <Box sx={{ backgroundColor: "grey.50" }}>
          <SearchField
            value={search}
            onChange={changeSearch}
            label={t({ id: "common.search", defaultMessage: "Suche" })}
          />
          {searchedMarkes.length > 0 && (
            <Box sx={{ backgroundColor: "grey.200" }}>
              <List
                value={markeFilter || []}
                options={searchedMarkes}
                setValueHandler={(v: any) => {
                  const diff = difference(v, markeFilter);
                  toggleMarke(diff[0]);
                }}
              />
            </Box>
          )}
          {searchedModells.length > 0 && (
            <List
              value={modellFilter || []}
              options={searchedModells}
              setValueHandler={toggleModell}
            />
          )}
        </Box>
        <Divider sx={{ mb: 2 }} />
        <ListItemButton
          id="filter-marke-model-filter-all"
          key="all"
          role={undefined}
          dense
          disableRipple
          onClick={() => selectAll()}
        >
          <ListItemIcon style={{ minWidth: 40 }}>
            <Checkbox
              color="primary"
              edge="start"
              checked={!(markeFilter.length || modellFilter.length)}
              tabIndex={-1}
              inputProps={{ "aria-labelledby": "checkbox-list-label-all" }}
            />
          </ListItemIcon>
          <ListItemText
            className="list-item-text"
            primary="Alle"
            primaryTypographyProps={{
              variant: "body1",
              component: "span",
            }}
          />
        </ListItemButton>
      </MuiList>
      <Divider />
      <MuiList>
        {options.map(({ key, values: modellCollectionOfMarke }: any) => {
          const markeId = parseInt(key);
          const markeName = markeCollection[markeId].name;

          const parsedOptions = modellCollectionOfMarke.map(
            ({ marke, name, id, modell_gruppe }: any) => ({
              title: name,
              value: id,
              marke,
              modell_gruppe_id: modell_gruppe,
              modell_gruppe_name: modell_gruppe
                ? modellGruppeCollection[modell_gruppe].name
                : null,
            })
          );

          const markeIsChecked = markeFilter.includes(markeId);
          const oneModellOfMarkeIsChecked =
            (parsedOptions.find(({ modell_gruppe_id }: any) =>
              modellGruppeFilter.includes(modell_gruppe_id)
            ) &&
              true) ||
            false;
          const oneModellGruppeOfMarkeIsChecked =
            (parsedOptions.find(({ value }: any) =>
              modellFilter.includes(value)
            ) &&
              true) ||
            false;

          const favourite = favouritesMarke.includes(markeId);
          const divider = lastFavourite && !favourite;
          lastFavourite = favourite;
          return (
            <>
              {divider && <Divider />}
              <Marke
                favourite={favourite}
                key={markeId}
                {...{
                  toggleMarke,
                  markeName,
                  toggleGruppe,
                  markeId,
                  setValueHandler: toggleModell,
                  parsedOptions,
                  value: modellFilter,
                  checked: markeIsChecked,
                  indeterminate:
                    oneModellOfMarkeIsChecked ||
                    oneModellGruppeOfMarkeIsChecked,
                  color:
                    markeFilter.length || modellFilter.length
                      ? "inherit"
                      : undefined,
                }}
              />
            </>
          );
        })}
      </MuiList>
    </Box>
  );

  return (
    <Filter
      component={Component}
      disabled={disabled}
      title={
        <FormattedMessage
          id="filter.marke_modell_gruppe.label"
          defaultMessage="Marke / Modell"
        />
      }
    />
  );
};

interface IMarke {
  markeName: string;
  markeId: string | number;
  setMarkeHandler?: any;
  setValueHandler: any;
  parsedOptions: any;
  value: any;
  toggleMarke: any;
  toggleGruppe: any;
  checked: boolean;
  indeterminate: boolean;
  color?: string;
  favourite?: boolean;
}

const Marke: React.FC<IMarke> = ({
  markeName,
  markeId,
  setMarkeHandler,
  setValueHandler,
  parsedOptions,
  value,
  toggleMarke,
  toggleGruppe,
  checked = false,
  indeterminate = false,
  favourite = false,
  color = "#BC1018",
}) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);
  return (
    <React.Fragment>
      <ListItemButton
        id={`filter-marke-${markeId}-all`}
        key={`filter-marke-${markeId}-all`}
        role={undefined}
        dense
        disableRipple
        // sx={{ background: favourite ? "#EEE" : "inherit" }}
      >
        <ListItemIcon style={{ minWidth: 30 }}>
          <Checkbox
            color="primary"
            checked={checked}
            indeterminate={indeterminate}
            edge="start"
            style={{ color: checked || indeterminate ? "#BC1018" : color }}
            tabIndex={-1}
            inputProps={{
              "aria-labelledby": "checkbox-list-label-all",
            }}
            onClick={() => toggleMarke(markeId)}
          />
        </ListItemIcon>
        <ListItemText
          id="checkbox-list-label-all"
          primary={markeName}
          primaryTypographyProps={{
            variant: "body1",
            component: "span",
          }}
          onClick={() => toggleOpen()}
        />
        {open ? (
          <ExpandLess fontSize="small" onClick={() => toggleOpen()} />
        ) : (
          <ExpandMore fontSize="small" onClick={() => toggleOpen()} />
        )}
      </ListItemButton>

      <Collapse style={{ backgroundColor: "#FAFAFA" }} in={open} unmountOnExit>
        <Box ml={2}>
          <Modell
            value={value}
            options={parsedOptions}
            setValueHandler={setValueHandler}
            toggleGruppe={toggleGruppe}
            color={checked ? "#BC1018" : "inherit"}
          />
        </Box>
      </Collapse>
    </React.Fragment>
  );
};
interface IModell {
  value: any;
  options: any;
  setValueHandler: any;
  toggleGruppe: any;
  color: any;
}

const Modell: React.FC<IModell> = ({
  value,
  options,
  setValueHandler,
  toggleGruppe,
  color,
}) => {
  const {
    state: {
      filter: { modell: modellFilter, modell_gruppe: modellGruppeFilter },
    },
  } = React.useContext(FilterStore);

  const groupedOptions = d3Collection
    .nest()
    .key(({ modell_gruppe_name }: any) => modell_gruppe_name)
    .sortKeys((modellGruppeA: string, modellGruppeB: string) => {
      if (modellGruppeA === "null") return -1;
      if (modellGruppeB === "null") return 1;
      return modellGruppeA > modellGruppeB ? 1 : -1;
    })
    .sortValues(({ title: nameA }: any, { title: nameB }: any) => {
      return nameA > nameB ? 1 : -1;
    })
    .entries(options);

  return (
    <>
      {groupedOptions.map(({ key, values }) => {
        const Component = key === "null" ? List : Group;
        const modellGruppeId = values[0].modell_gruppe_id;
        const checked = modellGruppeFilter.includes(modellGruppeId);
        const indeterminate =
          (values.find(({ value }: any) => modellFilter.includes(value)) &&
            true) ||
          false;
        return (
          <Component
            value={value}
            options={values}
            checked={checked}
            indeterminate={indeterminate}
            setValueHandler={setValueHandler}
            color={color}
            toggleGruppe={toggleGruppe}
          />
        );
      })}
    </>
  );
};

interface IGroup {
  value: any;
  options: any;
  setValueHandler: any;
  toggleGruppe: any;
  color: any;
  checked: boolean;
  indeterminate: boolean;
}

const Group: React.FC<IGroup> = ({
  value,
  options,
  setValueHandler,
  toggleGruppe,
  color,
  checked,
  indeterminate,
}) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  const groupId = options[0].modell_gruppe_id;
  const groupName = options[0].modell_gruppe_name;

  return (
    <React.Fragment>
      <ListItemButton
        id={`filter-marke-${groupId}-all`}
        key={`filter-marke-${groupId}-all`}
        role={undefined}
        dense
        disableRipple
      >
        <ListItemIcon style={{ minWidth: 30 }}>
          <Checkbox
            color="primary"
            checked={checked}
            indeterminate={indeterminate}
            edge="start"
            // style={{ color: checked || indeterminate ? "#BC1018" : color }}
            tabIndex={-1}
            inputProps={{
              "aria-labelledby": "checkbox-list-label-all",
            }}
            onClick={() => toggleGruppe(groupId)}
          />
        </ListItemIcon>
        <ListItemText
          id="checkbox-list-label-all"
          primary={groupName}
          primaryTypographyProps={{
            variant: "body1",
            component: "span",
          }}
          onClick={() => toggleOpen()}
        />
        {open ? (
          <ExpandLess fontSize="small" onClick={() => toggleOpen()} />
        ) : (
          <ExpandMore fontSize="small" onClick={() => toggleOpen()} />
        )}
      </ListItemButton>

      <Collapse style={{ backgroundColor: "#FAFAFA" }} in={open} unmountOnExit>
        <Box ml={2}>
          <List
            value={value}
            options={options}
            setValueHandler={setValueHandler}
            color={"inherit"}
          />
        </Box>
      </Collapse>
    </React.Fragment>
  );
};

const difference = (arr1: any, arr2: any) =>
  arr1
    .filter((x: any) => !arr2.includes(x))
    .concat(arr2.filter((x: any) => !arr1.includes(x)));

export default MarkeModellGruppeFilter;
