import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CatalogStore } from "stores";

import Chip from "../../ChipIndicator";

interface IModellIndicator {
  filter: number[];
  dispatch?: any;
}

const ModellIndicator: React.FC<IModellIndicator> = ({
  filter,
  dispatch,
}): JSX.Element | null => {
  const {
    state: {
      entities: { modell },
    },
  } = React.useContext(CatalogStore);
  const navigate = useNavigate();
  const { modellId = null } = useParams();

  if (!(filter.length && modell)) return null;

  const handleDelete = (modell: any) => {
    if (
      JSON.stringify(filter) === JSON.stringify([modell]) ||
      modellId === modell
    ) {
      navigate("/catalog");
    }
    dispatch({
      type: "REMOVE_ID_FROM_FILTER",
      payload: { filter: "modell", id: modell },
    });
  };
  return (
    <>
      {filter.map((id: number) => (
        <Chip
          id={id}
          key={id}
          label={modell[id].name}
          onDelete={() => handleDelete(id)}
        />
      ))}
    </>
  );
};

export default ModellIndicator;
