import React from "react";

import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { ConfigurationStore } from "stores";

import { IntlContext } from "intl";

const LanguageSwitch: React.FC = (): JSX.Element | null => {
  const { locale, setLocale } = React.useContext(IntlContext);
  const {
    intl: { allowedLocales },
  }: any = React.useContext(ConfigurationStore);

  const handleChange = (event: SelectChangeEvent) => {
    setLocale(event.target.value as string);
  };

  if (!allowedLocales || allowedLocales.length === 1) return null;

  return (
    <Select
      sx={{
        height: 40,
        borderRadius: 1,
        backgroundColor: "grey.400",
      }}
      value={locale}
      onChange={handleChange}
    >
      {allowedLocales.map((lang: string) => (
        <MenuItem key={lang} value={lang}>
          {lang}
        </MenuItem>
      ))}
    </Select>
  );
};

export default LanguageSwitch;
