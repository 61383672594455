import React from "react";
import { useIntl } from "react-intl";

import Box from "@mui/material/Box";
import { TCatalogEntities } from "types";

import List from "../../List";
import Filter from "../../Filter";

import Reducer from "./GetriebeReducer";
import Indicator from "./GetriebeIndicator";

import Pipeline from "../Pipeline";

interface IFilter {
  entities: TCatalogEntities;
  value: number[];
  dispatchFilter?: any;
}

export const GetriebeFilter: React.FC<IFilter> = ({
  entities,
  value = [],
  dispatchFilter = () => null,
}): JSX.Element | null => {
  const { formatMessage: t } = useIntl();

  if (!entities.getriebe) return null;

  const items = Object.values(entities.getriebe);

  const vehiclesCount = items.length;

  const setValueHandler = (value: any) => {
    // Check if all variants are selected
    const newValue = value.length === vehiclesCount ? [] : value;
    dispatchFilter({ type: "UPDATE_FILTER", payload: { getriebe: newValue } });
  };
  const options = items.map(({ id, name }) => ({
    value: id,
    title: t({ id: `getriebe.${id}.label` }, { defaultMessage: name }),
  }));

  const all = {
    title: t({ id: "common.alle", defaultMessage: "Alle" }),
  };

  const Component = (
    <Box width={1}>
      <List {...{ value, options, setValueHandler, all }} />
    </Box>
  );

  return (
    <Filter
      component={Component}
      title={t({ id: "filter.getriebe.label", defaultMessage: "Getriebe" })}
      open={false}
    />
  );
};

const Container = () => {
  return (
    <Pipeline
      {...{
        type: "getriebe",
        defaultValue: [],
        Component: GetriebeFilter,
        Reducer,
        Indicator,
      }}
    />
  );
};

export default Container;
