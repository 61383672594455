import React from "react";
import { useIntl } from "react-intl";

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActionArea from "@mui/material/CardActionArea";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Chip } from "../components";

interface ICard {
  data: any;
  onClickHandler?: any;
  paperProps?: any;
}

const defaultPicture = "https://cdn.carbando.eu/develop/logo512.png";

const Component: React.FC<ICard> = ({
  data,
  paperProps = {},
  onClickHandler = () => null,
}): JSX.Element => {
  const { formatMessage: t, formatNumber: n, formatDate: d } = useIntl();
  const { picture, modell, rate, preis, fzarts } = data;
  const title = `${modell.marke.name} - ${modell.name}`;

  return (
    <Card className={"modelle"} square sx={{ boxShadow: 3 }}>
      <CardActionArea onClick={onClickHandler} disableRipple>
        <Box sx={{ position: "relative" }}>
          <CardMedia
            component="img"
            image={picture || defaultPicture}
            sx={{
              objectPosition: "top center",
              aspectRatio: "12/9",
            }}
            {...{ title, alt: title }}
          />
          <div className="image-overlay" />
        </Box>
        <CardHeader
          {...{ title, alt: title }}
          titleTypographyProps={{ variant: "h5", color: "primary" }}
          subheaderTypographyProps={{
            variant: "body1",
            color: "primary",
            sx: { textOverflow: "ellipsis" },
          }}
        />
        <CardContent sx={{ py: 0 }}>
          <Stack
            direction="row"
            spacing={1}
            sx={{ fontWeight: "bold", flexWrap: "wrap" }}
            useFlexGap
          >
            {fzarts.map(({ id, count }: { id: number; count: number }) => (
              <Chip label={t({ id: `fzart.${id}.plural` }, { count })} />
            ))}
          </Stack>
        </CardContent>
        <CardContent>
          <Stack direction="row" sx={{ justifyContent: "space-between" }}>
            <Box>
              <Typography color="primary" component="span" variant="body2">
                ab&nbsp;
              </Typography>
              <Typography
                component="span"
                color="primary"
                variant="h5"
                sx={{ fontWeight: "900" }}
              >
                {n(preis, {
                  style: "currency",
                  currency: "EUR",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </Typography>
            </Box>
            {rate && (
              <Box>
                <Typography color="primary" component="span" variant="body2">
                  ab&nbsp;
                </Typography>
                <Typography
                  component="span"
                  variant="h6"
                  color="primary"
                  sx={{ fontWeight: "900" }}
                >
                  {n(rate, {
                    style: "currency",
                    currency: "EUR",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </Typography>
              </Box>
            )}
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default Component;
