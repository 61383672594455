import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface ISorter {
  value: string;
  changeValueHandler: any;
  showEmpfohlen?: boolean;
}

const Sorter: React.FC<ISorter> = ({
  value,
  changeValueHandler: changeValue,
  showEmpfohlen = false,
}): JSX.Element | null => {
  if (value === "empfohlen" && showEmpfohlen === false) return null;

  const handleChange = (event: SelectChangeEvent) => {
    changeValue(event.target.value);
  };

  const LABEL = "Sortierung";
  return (
    <FormControl size="small" data-testid="page-catalog-component-sorter">
      <InputLabel id="page-catalog-component-sorter-label">{LABEL}</InputLabel>
      <Select
        labelId="page-catalog-component-sorter-label"
        id="page-catalog-component-sorter"
        value={value}
        label={LABEL}
        onChange={handleChange}
      >
        {showEmpfohlen && <MenuItem value={"empfohlen"}>Empfohlen</MenuItem>}
        <MenuItem value={"preis_asc"}>Preis aufsteigend</MenuItem>
        <MenuItem value={"preis_desc"}>Preis absteigend</MenuItem>
        <MenuItem value={"km_asc"}>km aufsteigend</MenuItem>
        <MenuItem value={"km_desc"}>km absteigend</MenuItem>
        <MenuItem value={"erstzulassung_asc"}>
          Erstzulassung aufsteigend
        </MenuItem>
        <MenuItem value={"erstzulassung_desc"}>
          Erstzulassung absteigend
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default Sorter;
