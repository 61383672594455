import { TVehicle } from "types";
import { rollups, extent, min, sum, group } from "d3-array";

export const groupVehicles = (vehicles: TVehicle[]): any => {
  const preparedVehicles = vehicles.map((vehicle: TVehicle) => {
    return {
      ...vehicle,
      groupType: vehicle.modell.modell_gruppe ? "gruppe" : "modell",
      groupId: vehicle.modell.modell_gruppe
        ? vehicle.modell.modell_gruppe.id
        : vehicle.modell.id,
      group: vehicle.modell.modell_gruppe
        ? "gruppe_" + vehicle.modell.modell_gruppe.id
        : "modell_" + vehicle.modell.id,
    };
  });

  const groupedVehicles = rollups(
    preparedVehicles,
    (v: any) => ({
      picture:
        v[0].pictures.length > 0 && v[0].pictures[0].src
          ? v[0].pictures[0].src
          : "",
      marke: v[0].modell.marke,
      modell: v[0].modell,
      id: v[0].wgnr,
      vorrat: sum(v, (d: any) => 1),
      rate: min(v, (d: any) => d.rate),
      preis: min(v, (d: any) => d.preis),
      groupType: v[0].groupType,
      groupId: v[0].groupId,
      group: v[0].group,
      fzarts: Array.from(group(v, ({ fzart }: any) => fzart.id)).map(
        ([id, elements]) => ({ id, count: elements.length })
      ),

      co2_effizienzklasse: extent(v, ({ co2_effizienzklasse }: any) =>
        check(co2_effizienzklasse)
      ),
      co2_emissionen: extent(v, (d: any) => d.co2_emissionen),
      verbrauch_kombiniert: extent(v, ({ verbrauch_kombiniert }: any) =>
        check(verbrauch_kombiniert)
      ),
      stromverbrauch_kombiniert: extent(
        v,
        ({ stromverbrauch_kombiniert }: any) => check(stromverbrauch_kombiniert)
      ),
    }),
    (d: any) => d.group
  );
  return groupedVehicles;
};

const check = (d: any) => (d ? d : null);
