import React from "react";

const defaultConfiguration: any = null;
const getConfigurarion = async () => {
  const response = await fetch(
    `${process.env.REACT_APP_CDN_SERVER}/${process.env.REACT_APP_SHOP_ID}/configuration.json`
  );
  if (response.status !== 200) {
    return {};
  }
  let data = await response.json();
  return data;
};

export const ConfigurationStore = React.createContext(defaultConfiguration);

const Configuration: React.FC = ({ children }): JSX.Element | null => {
  const [configuration, setConfigurarion] = React.useState(null);

  React.useEffect(() => {
    const loadConfiguration = async () => {
      const data = await getConfigurarion();
      setConfigurarion(data);
    };

    loadConfiguration();
  }, []);

  if (!configuration) return null;

  return (
    <ConfigurationStore.Provider value={configuration}>
      {children}
    </ConfigurationStore.Provider>
  );
};

export default Configuration;
