import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { extent } from "d3-array";
import dayjs from "dayjs";
import { TCatalogEntities } from "types";

import Box from "@mui/material/Box";
import Slider from "../Slider";

import Pipeline from "../Pipeline";

import Filter from "../../Filter";

import Reducer from "./ErstzulassungReducer";
import Indicator from "./ErstzulassungIndicator";

interface IFilter {
  entities: TCatalogEntities;
  value: number[];
  dispatchFilter?: any;
}

export const ErstzulassungFilter: React.FC<IFilter> = ({
  entities: { vehicle },
  value = [],
  dispatchFilter = () => null,
}): JSX.Element | null => {
  const [maximum, setMaximum]: [number | undefined, any] = useState();
  const [minimum, setMinimum]: [number | undefined, any] = useState();
  const [range, setRange]: [[number, number] | undefined, any] = useState();

  React.useEffect(
    () => {
      if (!vehicle) return;
      const range: [any, any] = extent(
        Object.values(vehicle)
          .map((e: any) => e.erstzulassung)
          .filter((e: any) => !!e)
          .map((e: string) => dayjs(e, "YYYY-MM-DD").year())
      );
      setRange(range);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vehicle]
  );

  React.useEffect(
    () => {
      if (!(vehicle && range)) return;
      if (!value) {
        setMinimum(range[0]);
        setMaximum(range[1]);
      } else {
        setMinimum(value[0]);
        setMaximum(value[1]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, range]
  );

  const handleChange = (element: any, newValue: any) => {
    setMinimum(newValue[0]);
    setMaximum(newValue[1]);
  };

  const submit = (element: any, value: any) => {
    const newValue =
      JSON.stringify(range) === JSON.stringify(value) ? null : value;
    dispatchFilter({
      type: "UPDATE_FILTER",
      payload: { erstzulassung: newValue },
    });
  };

  if (minimum === undefined || maximum === undefined || !range) return null;

  const marks = [
    { value: range[0], label: range[0] },
    { value: range[1], label: range[1] },
  ];

  if (minimum > range[0]) marks.push({ value: minimum, label: minimum });
  if (maximum < range[1] && minimum !== maximum)
    marks.push({ value: maximum, label: maximum });

  const options = {
    range,
    onChangeHandler: handleChange,
    onChangeCommittedHandler: submit,
    marks,
    step: 1,
  };

  const Component = (
    <Box sx={{ px: 1 }}>
      <Slider inverted value={[minimum, maximum]} {...options} />
    </Box>
  );

  return (
    <Filter
      component={Component}
      title={
        <FormattedMessage
          id="filter.erstzulassung.label"
          defaultMessage="Erstzulassung"
        />
      }
    />
  );
};

const Container = () => {
  return (
    <Pipeline
      {...{
        type: "erstzulassung",
        defaultValue: null,
        Component: ErstzulassungFilter,
        Reducer,
        Indicator,
      }}
    />
  );
};

export default Container;
