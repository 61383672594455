import React from "react";
import { useNavigate } from "react-router-dom";

import { VehicleStore, FilterStore } from "stores";
import ModelleCard from "./Card";
import { ConfigurationStore } from "stores";

import Box from "@mui/material/Box";

import Header from "./Header";
import { EmptySearch } from "../";
import { groupVehicles } from "../helper";

const Modelle: React.FC = (): JSX.Element | null => {
  const { state: vehicles } = React.useContext(VehicleStore);
  const configuration = React.useContext(ConfigurationStore);

  const {
    state: { order: sortOrder },
    dispatch: dispatchFilter,
  } = React.useContext(FilterStore);
  // const sortOrder = filters.filter.finance?.type === "lease" ? "rate" : "preis";

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  if (!vehicles) return null;
  if (vehicles.length === 0) return <EmptySearch />;

  const favouritesMarke = configuration?.filter?.favourites?.marke || undefined;

  const sorter = {
    empfohlen: (a: any, b: any) => {
      if (favouritesMarke) {
        if (favouritesMarke.includes(a[1].marke.id)) {
          if (favouritesMarke.includes(b[1].marke.id)) {
            return b[1]["vorrat"] < a[1]["vorrat"] ? -1 : 1;
          }
          return -1;
        }
        if (favouritesMarke.includes(b[1].marke.id)) {
          return 1;
        }
      }
      return b[1]["vorrat"] < a[1]["vorrat"] ? -1 : 1;
    },

    preis_asc: (a: any, b: any) => {
      return b[1]["preis"] > a[1]["preis"] ? -1 : 1;
    },
    preis_desc: (a: any, b: any) => {
      return b[1]["preis"] < a[1]["preis"] ? -1 : 1;
    },
    km_asc: (a: any, b: any) => {
      return 1;
    },
    km_desc: (a: any, b: any) => {
      return 1;
    },
    erstzulassung_asc: (a: any, b: any) => {
      return 1;
    },
    erstzulassung_desc: (a: any, b: any) => {
      return 1;
    },
  };

  const groups = groupVehicles(vehicles).sort(sorter[sortOrder]);

  const setModell = (id: any) => {
    const payload = {
      type: "UPDATE_FILTER",
      payload: { modell: [id] },
    };
    dispatchFilter(payload);
    navigate("/catalog/mo/" + id);
  };

  const setModellGroup = (id: any) => {
    const payload = {
      type: "UPDATE_FILTER",
      payload: { modell_gruppe: [id] },
    };
    dispatchFilter(payload);
    navigate("/catalog/mg/" + id);
  };

  const detail = (id: any) => {
    navigate("/catalog/dt/" + id);
  };
  return (
    <>
      <Header />
      <Box
        sx={{
          display: "grid",
          columnGap: { xs: 1, sm: 2, md: 3, lg: 4 },
          rowGap: { xs: 1, sm: 2, md: 3, lg: 4 },
          gridTemplateColumns: {
            lg: "repeat(3, 1fr)",
            sm: "repeat(2, 1fr)",
            xs: "repeat(1, 1fr)",
          },
        }}
      >
        {groups.map(([ndx, group]: [string, any]) => {
          const Card = group.groupType === "gruppe" ? ModelleCard : ModelleCard;
          const handler =
            group.vorrat === 1
              ? detail
              : group.groupType === "gruppe"
                ? setModellGroup
                : setModell;
          const id = group.vorrat === 1 ? group.id : group.groupId;

          return (
            <Card key={id} data={group} onClickHandler={() => handler(id)} />
          );
        })}
      </Box>
    </>
  );
};

export default Modelle;
