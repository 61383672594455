import React from "react";
import { Helmet } from "react-helmet-async";

import { CatalogStore, ConfigurationStore } from "stores";

interface IHeader {
  modellId: string;
}

const Header: React.FC<IHeader> = ({ modellId }): JSX.Element | null => {
  const configuration = React.useContext(ConfigurationStore);
  const {
    state: { entities },
  } = React.useContext(CatalogStore);
  const href = window.location.href;

  if (!(entities.modell && entities.marke)) return null;

  const modell = entities.modell[modellId];
  if (!modell) return null;
  const marke = entities.marke[modell.marke];
  if (!marke) return null;
  const title = configuration.application.title || "carbando catalog";

  return (
    <Helmet>
      <title>{`${title} - ${marke.name} ${modell.name}`}</title>
      <meta
        property="og:title"
        content={`${title} - ${marke.name} ${modell.name}`}
      />
      <meta property="og:url" content={href} />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content={title} />
    </Helmet>
  );
};

export default Header;
