import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import {
  Header,
  Footer,
  Breadcrumb,
  CookieWall,
  ContactSpeedDial,
  HistoryPage,
} from "containers";
import { ConfigurationStore } from "stores";

import { Home, Catalog, Error404, Checkout } from "pages";
import { ExtMd } from "components";

const App = () => {
  const configuration = React.useContext(ConfigurationStore);
  const isInline = !!configuration.application.inline;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Frame inline={isInline}>
        <Breadcrumb />
        <Box sx={{ flexGrow: 1, background: "grey.200" }}>
          <Container maxWidth={"xl"} sx={{ p: { xs: 0, sm: 1 } }}>
            <Routes>
              <Route index element={<Navigate replace to="catalog" />} />
              <Route path="/" element={<Home />} />
              <Route path="/catalog/*" element={<Catalog />} />
              <Route path="/history" element={<HistoryPage />} />
              {!!configuration.features?.checkout && (
                <Route path="/checkout/*" element={<Checkout />} />
              )}
              {!isInline && (
                <>
                  <Route
                    path="/withdrawal"
                    element={<ExtMd topic="withdrawal" />}
                  />
                  <Route path="/imprint" element={<ExtMd topic="imprint" />} />
                  <Route path="/privacy" element={<ExtMd topic="privacy" />} />
                  <Route path="/agb" element={<ExtMd topic="agb" />} />
                </>
              )}
              <Route path="/error/404" element={<Error404 />} />
              <Route path="*" element={<Error404 />} />
            </Routes>
          </Container>
        </Box>
        <HideInline inline={isInline}>
          <CookieWall />
        </HideInline>
        {!!configuration.features?.contact && (
          <ContactSpeedDial sx={{ position: "fixed", right: 10, bottom: 10 }} />
        )}
      </Frame>
    </Box>
  );
};

interface IInline {
  inline: boolean;
}

const Frame: React.FC<IInline> = ({ children, inline = true }): JSX.Element => {
  const isInline = !!inline;
  if (isInline) return <>{children}</>;
  return (
    <>
      <Header />
      {children} <Footer />
    </>
  );
};

const HideInline: React.FC<IInline> = ({
  children,
  inline = true,
}): JSX.Element | null => {
  const isInline = !!inline;

  if (isInline) return null;
  return <>{children}</>;
};

export default App;
