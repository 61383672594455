import React from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

import { TVehicle } from "types";
import { VehicleStore, FilterStore, CatalogStore } from "stores";
import Card from "./Card";

import Box from "@mui/material/Box";

import Header from "./Header";
import { EmptySearch } from "../";

const Modell: React.FC = (): JSX.Element | null => {
  const { state: vehicles } = React.useContext(VehicleStore);
  const {
    state: { order: sortOrder },
  } = React.useContext(FilterStore);
  const {
    state: { result: catalogIds },
  } = React.useContext(CatalogStore);

  const { modell_id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (!(catalogIds?.modell && modell_id)) return;
    if (!catalogIds.modell.includes(parseInt(modell_id))) {
      navigate("/catalog", { replace: true });
      return;
    }
  }, [catalogIds, modell_id, navigate]);

  if (!vehicles) return null;
  if (!modell_id || !vehicles || vehicles.length === 0) return <EmptySearch />;

  const filteredVehicles = modell_id
    ? filterVehicles(vehicles, parseInt(modell_id))
    : vehicles;

  const sorter = {
    empfohlen: (a: any, b: any) => {
      return -1;
    },

    preis_asc: (a: any, b: any) => {
      return b["preis"] > a["preis"] ? -1 : 1;
    },
    preis_desc: (a: any, b: any) => {
      return b["preis"] < a["preis"] ? -1 : 1;
    },
    km_asc: (a: any, b: any) => {
      return b["km"] > a["km"] ? -1 : 1;
    },
    km_desc: (a: any, b: any) => {
      return b["km"] < a["km"] ? -1 : 1;
    },
    erstzulassung_asc: (a: any, b: any) => {
      return b["erstzulassung"] > a["erstzulassung"] ? -1 : 1;
    },
    erstzulassung_desc: (a: any, b: any) => {
      return b["erstzulassung"] < a["erstzulassung"] ? -1 : 1;
    },
  };

  const sortedvehicles = filteredVehicles.sort(sorter[sortOrder]);

  return (
    <>
      <Header modellId={modell_id} />
      <Box
        sx={{
          display: "grid",
          columnGap: { xs: 1, sm: 2, md: 3, lg: 4 },
          rowGap: { xs: 1, sm: 2, md: 3, lg: 4 },
          gridTemplateColumns: {
            lg: "repeat(3, 1fr)",
            sm: "repeat(2, 1fr)",
            xs: "repeat(1, 1fr)",
          },
        }}
      >
        {sortedvehicles.map((vehicle: TVehicle) => {
          return (
            <Card
              vehicle={vehicle}
              onClickHandler={() => navigate("/catalog/dt/" + vehicle.wgnr)}
              key={vehicle.wgnr}
            />
          );
        })}
      </Box>
    </>
  );
};

const filterVehicles = (vehicles: TVehicle[], id: number): any => {
  const results = vehicles.filter((v: any) => v.modell.id === id);
  return results;
};

export default Modell;
