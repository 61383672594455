import React from "react";
import { useSearchParams } from "react-router-dom";

import { CatalogStore, FilterStore } from "stores";

interface IPipeline {
  type: string;
  defaultValue: any;
  Component: any;
  Reducer: any;
  Indicator: any;
}

const Pipeline: React.FC<IPipeline> = ({
  type,
  defaultValue,
  Component,
  Reducer,
  Indicator,
}) => {
  const {
    state: { entities },
  } = React.useContext(CatalogStore);
  const {
    state: {
      filter: { [type]: value = [] },
    },
    dispatch: dispatchFilter,
  } = React.useContext(FilterStore);
  const [searchParams] = useSearchParams();
  React.useEffect(
    () => {
      dispatchFilter({
        type: "ADD_REDUCER",
        payload: { key: type, value: Reducer },
      });
      dispatchFilter({
        type: "ADD_INDICATOR",
        payload: { key: type, value: Indicator },
      });
      const params = searchParams.get(type);
      const value = params
        ? params.split(",").map((e) => parseInt(e))
        : defaultValue;
      dispatchFilter({
        type: "INIT_FILTER",
        payload: {
          value: { [type]: value },
          reset: { [type]: defaultValue },
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  if (!entities) return null;

  return <Component {...{ entities, value, dispatchFilter }} />;
};

export default Pipeline;
