import React from "react";
import { Helmet } from "react-helmet-async";

import { CatalogStore, ConfigurationStore } from "stores";
interface IHeader {
  id: string;
}

const Header: React.FC<IHeader> = ({ id }): JSX.Element | null => {
  const configuration = React.useContext(ConfigurationStore);
  const {
    state: {
      entities: { modell_gruppe: modellGruppeEntities },
    },
  } = React.useContext(CatalogStore);
  const href = window.location.href;

  if (!modellGruppeEntities) return null;

  const modellGruppe = modellGruppeEntities[id];
  if (!modellGruppe) return null;
  const title = configuration.application.title || "carbando catalog";

  return (
    <Helmet>
      <title>{`${title} - ${modellGruppe.name}`}</title>
      <meta property="og:title" content={`${title} - ${modellGruppe.name}`} />
      <meta property="og:url" content={href} />
      <meta property="og:type" content="article" />
      <meta property="og:site_name" content={title} />
    </Helmet>
  );
};

export default Header;
