import React from "react";
import { FormattedMessage } from "react-intl";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { CatalogStore, FilterStore } from "stores";

const Breadcrumb: React.FC = (): JSX.Element | null => {
  const { pathname } = useLocation();
  const {
    state: { entities, result: catalogIds },
  } = React.useContext(CatalogStore);
  const {
    state: { filter },
    dispatch: dispatchFilter,
  } = React.useContext(FilterStore);
  React.useEffect(() => {
    if (!(entities?.vehicle && catalogIds?.modell && catalogIds?.modell_gruppe))
      return;
    const paths = pathname.split("/");
    paths.shift();
    const environment = paths.shift();

    if (paths.length === 2) {
      const [topic, uid] = paths;
      const id = parseInt(uid);

      switch (topic) {
        case "mo":
          if (
            JSON.stringify(filter.modell) !== JSON.stringify([id]) &&
            catalogIds.modell.includes(id)
          ) {
            const payload = {
              type: "UPDATE_FILTER",
              payload: { modell: [id] },
            };
            dispatchFilter(payload);
          }
          break;
        case "mg":
          if (
            JSON.stringify(filter.modell_gruppe) !== JSON.stringify([id]) &&
            catalogIds.modell_gruppe.includes(id)
          ) {
            const payload = {
              type: "UPDATE_FILTER",
              payload: { modell_gruppe: [id] },
            };
            dispatchFilter(payload);
          }
          break;
      }
      // } else if (paths.length === 0) {
      //   switch (environment) {
      //     case "catalog":
      //       const payload = {
      //         type: "UPDATE_FILTER",
      //         payload: { modell: [], modell_gruppe: [] },
      //       };
      //       dispatchFilter(payload);
      //       break;
      //   }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, dispatchFilter, entities]);

  if (!entities?.vehicle) return null;
  const breadcrumb = pathname2breadcrumb(pathname, entities);
  if (!breadcrumb) return null;

  const checkFilter = (ndx: number) => {
    if (ndx === 0) {
      const payload = {
        type: "UPDATE_FILTER",
        payload: { modell: [] },
      };
      dispatchFilter(payload);
    }
  };

  return (
    <Box
      sx={{
        px: 1,
        bgcolor: "grey.100",
        typography: "body2",
        pl: { xs: 4, md: 1 },
      }}
    >
      <Container maxWidth={"xl"}>
        <Stack
          direction="row"
          justifyContent="left"
          alignItems="center"
          divider={<ArrowForwardIosIcon fontSize="inherit" />}
        >
          {breadcrumb.map(
            ({ label, link }: { label: any; link: string }, ndx: number) => {
              return (
                <Button
                  disabled={ndx === breadcrumb.length - 1}
                  component={Link}
                  to={link}
                  key={ndx}
                  color="primary"
                  onClick={() => checkFilter(ndx)}
                >
                  {label}
                </Button>
              );
            }
          )}
        </Stack>
      </Container>
    </Box>
  );
};

export default Breadcrumb;

const pathname2breadcrumb = (pathname: string, entities: any): any => {
  const paths = pathname.split("/");
  paths.shift();
  const environment = paths.shift();
  const breadcrumb = [];
  breadcrumb.push({
    label: (
      <FormattedMessage id={"navigation." + environment} defaultMessage="!" />
    ),
    link: `/${environment}`,
  });
  switch (environment) {
    case "checkout": {
      breadcrumb.push({
        label: <FormattedMessage id={"navigation." + paths[0]} />,
        link: ".",
      });
      break;
    }

    case "history": {
      breadcrumb.unshift({
        label: <FormattedMessage id={"navigation.catalog"} />,
        link: `/catalog`,
      });
      break;
    }

    case "catalog": {
      const [topic, id] = paths;
      switch (topic) {
        case "mo": {
          const modell = entities.modell[id];
          if (!modell) return null;
          const marke = entities.marke[modell.marke];
          breadcrumb.push({
            label: marke.name + " " + modell.name,
            link: [environment, "mo", id].join("/"),
          });
          break;
        }
        case "mg": {
          const modell_gruppe = entities.modell_gruppe[id];
          if (!modell_gruppe) return null;
          breadcrumb.push({
            label: modell_gruppe.name,
            link: [environment, "mg", id].join("/"),
          });
          break;
        }

        case "dt": {
          const vehicle = entities.vehicle[id];
          if (!vehicle) return null;
          const modell = entities.modell[vehicle.modell];
          const marke = entities.marke[modell.marke];
          breadcrumb.push({
            label: marke.name + " " + modell.name,
            link: [environment, "mo", modell.id].join("/"),
          });

          breadcrumb.push({
            label: vehicle.version,
            link: [environment, ...paths].join("/"),
          });
          break;
        }
      }

      break;
    }
    default: {
    }
  }
  return breadcrumb;
};
