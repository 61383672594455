import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { scaleLinear } from "d3-scale";
import { extent } from "d3-array";

import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import { TCatalogEntities } from "types";

import Pipeline from "../Pipeline";
import AutoComplete from "../Autocomplete";
import { default as SideFilter } from "../../Filter";

import KilometerReducer from "./KilometerReducer";
import KilometerIndicator from "./KilometerIndicator";

interface IInfo {
  value: [number, number] | undefined;
  range: [number, number];
}

const Info: React.FC<IInfo> = ({ value, range }): JSX.Element => {
  let id = "common.kilometer.span";
  let values = null;
  if (!value) {
    values = { from: range[0], to: range[1] };
  } else if (value[0] === value[1]) {
    id = "common.kilometer.plural";
    values = { count: value[0] };
  } else {
    values = { from: value[0], to: value[1] };
  }

  return (
    <Typography sx={{ mb: 2 }} variant="body1" color="secondary">
      <FormattedMessage {...{ id, values }} />
    </Typography>
  );
};

interface IFilter {
  entities: TCatalogEntities;
  value: number[];
  dispatchFilter?: any;
}

export const KilometerFilter: React.FC<IFilter> = ({
  entities: { vehicle },
  value = [],
  dispatchFilter = () => null,
}): JSX.Element | null => {
  const [maximum, setMaximum]: [number | undefined, any] = useState();
  const [minimum, setMinimum]: [number | undefined, any] = useState();
  const [range, setRange]: [[number, number] | undefined, any] = useState();
  React.useEffect(() => {
    if (!vehicle) return;
    const edges: [any, any] = extent(Object.values(vehicle), (v) => v.km);
    const scale = scaleLinear().domain(edges).range([0, 1]).nice();
    setRange([scale.invert(0), scale.invert(1)]);
  }, [vehicle, dispatchFilter]);

  React.useEffect(
    () => {
      if (!(vehicle && range)) return;
      if (!value) {
        setMinimum(range[0]);
        setMaximum(range[1]);
      } else {
        setMinimum(value[0]);
        setMaximum(value[1]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, range]
  );

  const handleChange = (element: any, newValue: any) => {
    setMinimum(newValue[0]);
    setMaximum(range && newValue[1] > range[1] ? range[1] : newValue[1]);
  };

  const submit = (element: any, value: any) => {
    const newValue =
      JSON.stringify(range) === JSON.stringify(value) ? null : value;
    dispatchFilter({
      type: "UPDATE_FILTER",
      payload: { kilometer: newValue },
    });
  };

  if (minimum === undefined || maximum === undefined || !range) return null;

  const Component = (
    <Box sx={{ px: 1 }}>
      <Info value={[minimum, maximum]} range={range} />
      <AutoComplete
        value={[minimum, maximum]}
        {...{
          range,
          onChangeHandler: handleChange,
          onChangeCommittedHandler: submit,
        }}
      />
    </Box>
  );

  return (
    <SideFilter
      component={Component}
      open={false}
      title={
        <FormattedMessage
          id="filter.kilometer.label"
          defaultMessage="Kilometer"
        />
      }
    />
  );
};

const Container = () => {
  return (
    <Pipeline
      {...{
        defaultValue: null,
        type: "kilometer",
        Component: KilometerFilter,
        Reducer: KilometerReducer,
        Indicator: KilometerIndicator,
      }}
    />
  );
};

export default Container;
