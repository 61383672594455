import React, { useState } from "react";
import { useIntl } from "react-intl";
import { extent } from "d3-array";

import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

import { TCatalogEntities } from "types";

import Pipeline from "../Pipeline";
import { default as SideFilter } from "../../Filter";

import LeistungReducer from "./LeistungReducer";
import LeistungIndicator from "./LeistungIndicator";
import { Typography } from "@mui/material";

interface IFilter {
  entities: TCatalogEntities;
  value: number[];
  dispatchFilter?: any;
}

export const LeistungFilter: React.FC<IFilter> = ({
  entities: { vehicle },
  value = [],
  dispatchFilter = () => null,
}): JSX.Element | null => {
  const [maximum, setMaximum]: [number | undefined, any] = useState();
  const [minimum, setMinimum]: [number | undefined, any] = useState();
  const [range, setRange]: [[number, number] | undefined, any] = useState();

  const { formatMessage: t } = useIntl();

  React.useEffect(() => {
    if (!vehicle) return;
    const range: [any, any] = extent(
      Object.values(vehicle)
        .map((e: any) => e.kw)
        .filter((e: any) => !!e)
    );
    setRange(range);
  }, [vehicle, dispatchFilter]);

  React.useEffect(
    () => {
      if (!(vehicle && range)) return;
      if (!value) {
        setMinimum(range[0]);
        setMaximum(range[1]);
      } else {
        setMinimum(value[0]);
        setMaximum(value[1]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value, range]
  );

  const handleChange = (element: any, newValue: any) => {
    setMinimum(newValue[0]);
    setMaximum(newValue[1]);
  };

  const submit = (element: any, value: any) => {
    const newValue =
      JSON.stringify(range) === JSON.stringify(value) ? null : value;
    dispatchFilter({
      type: "UPDATE_FILTER",
      payload: { leistung: newValue },
    });
  };

  if (!(minimum && maximum && range)) return null;

  const marks = [
    { value: range[0], label: range[0] },
    { value: range[1], label: range[1] },
  ];

  if (minimum > range[0]) marks.push({ value: minimum, label: minimum });
  if (maximum < range[1] && minimum !== maximum)
    marks.push({ value: maximum, label: maximum });

  const options = {
    min: range[0],
    max: range[1],
    onChange: handleChange,
    onChangeCommitted: submit,
    marks,
  };

  const Component = (
    <Box sx={{ px: 1 }}>
      <Typography textAlign="right" variant="body1">
        in kW
      </Typography>
      <Slider value={[minimum, maximum]} {...options} />
    </Box>
  );

  return (
    <SideFilter
      component={Component}
      open={false}
      title={t({ id: "filter.leistung.label", defaultMessage: "Leistung" })}
    />
  );
};

const Container = () => {
  return (
    <Pipeline
      {...{
        type: "leistung",
        defaultValue: null,
        Component: LeistungFilter,
        Reducer: LeistungReducer,
        Indicator: LeistungIndicator,
      }}
    />
  );
};

export default Container;
