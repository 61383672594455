import React from "react";

import { CatalogStore } from "stores";

import Chip from "../../ChipIndicator";

interface IMarkeIndicator {
  filter: number[];
  dispatch?: any;
}

const MarkeIndicator = ({
  filter,
  dispatch,
}: IMarkeIndicator): JSX.Element | null => {
  const {
    state: {
      entities: { modell_gruppe },
    },
  } = React.useContext(CatalogStore);
  if (!(filter.length && modell_gruppe)) return null;

  const handleDelete = (modell_gruppe: any) => {
    dispatch({
      type: "REMOVE_ID_FROM_FILTER",
      payload: { filter: "modell_gruppe", id: modell_gruppe },
    });
  };
  return (
    <>
      {filter.map((id: number) => (
        <Chip
          id={id}
          key={id}
          label={modell_gruppe[id].name}
          onDelete={() => handleDelete(id)}
        />
      ))}
    </>
  );
};

export default MarkeIndicator;
