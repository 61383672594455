import React from "react";
import { useIntl } from "react-intl";

import MuiChip from "@mui/material/Chip";

/**
 * Chip with formatted Message
 */

interface ITChip {
  value: number | undefined;
  parameter: string | undefined;
  defaultMessage: string | undefined;
}

export const TChip: React.FC<ITChip> = ({
  value,
  parameter,
  defaultMessage,
}): JSX.Element | null => {
  const { formatMessage: t } = useIntl();
  if (!value) return null;

  return (
    <Chip
      label={
        value
          ? t({
              id: `${parameter}.${value}.label`,
              defaultMessage,
            })
          : null
      }
    />
  );
};

interface INChip {
  value: number | null;
  unit?: string;
}

export const NChip: React.FC<INChip> = ({
  value,
  unit,
}): JSX.Element | null => {
  const { formatNumber: n } = useIntl();
  if (!value) return null;

  return (
    <Chip
      label={
        n(value, {
          maximumFractionDigits: 0,
        }) + unit
      }
    />
  );
};
interface IDChip {
  value: string | undefined | null;
  prefix?: string;
  suffix?: string;
}

export const DChip: React.FC<IDChip> = ({
  value,
  prefix = "",
  suffix = "",
}): JSX.Element | null => {
  const { formatDate: d } = useIntl();
  if (!value) return null;

  return (
    <Chip
      label={
        prefix +
        d(value, {
          year: "numeric",
          month: "2-digit",
        }) +
        suffix
      }
    />
  );
};

interface IFChip {
  value: number | undefined | null;
  unit?: string;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
}

export const FChip: React.FC<IFChip> = ({
  value,
  unit,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0,
}): JSX.Element | null => {
  const { formatNumber: n } = useIntl();
  if (!value) return null;

  return (
    <Chip
      label={
        n(value, {
          minimumFractionDigits,
          maximumFractionDigits: maximumFractionDigits
            ? maximumFractionDigits
            : minimumFractionDigits,
        }) + unit
      }
    />
  );
};

interface IUChip {
  value: number | undefined | null;
  unit: string;
  minimumFractionDigits?: number;
  unitDisplay?: "short" | "long" | "narrow" | undefined;
}

export const UChip: React.FC<IUChip> = ({
  value,
  unit,
  minimumFractionDigits = 0,
  unitDisplay = "short",
}): JSX.Element | null => {
  const { formatNumber: n } = useIntl();
  if (!value) return null;

  return (
    <Chip
      label={n(value, {
        style: "unit",
        unit,
        unitDisplay,
        minimumFractionDigits,
      })}
    />
  );
};

interface IChip {
  label: string | null;
}

export const Chip: React.FC<IChip> = ({ label }): JSX.Element | null => {
  if (!label) return null;

  return <MuiChip {...{ label }} size="small" sx={{ px: 1 }} />;
};
