import React from "react";
import { useParams, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";

import { VehicleStore, FilterStore, CatalogStore } from "stores";

import { groupVehicles } from "../helper";

import Header from "./Header";
import ModelleCard from "../Modelle/Card";

const Modelle: React.FC = (): JSX.Element | null => {
  const { state: vehicles } = React.useContext(VehicleStore);
  const { state: filters, dispatch: dispatchFilter } =
    React.useContext(FilterStore);
  const {
    state: { result: catalogIds },
  } = React.useContext(CatalogStore);

  const sortOrder = filters.filter.finance?.type === "lease" ? "rate" : "preis";

  const { modell_gruppe_id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    if (!(catalogIds?.modell_gruppe && modell_gruppe_id)) return;
    if (!catalogIds.modell_gruppe.includes(parseInt(modell_gruppe_id))) {
      navigate("/catalog", { replace: true });
      return;
    }
  }, [catalogIds, modell_gruppe_id, navigate]);

  if (!modell_gruppe_id || !vehicles || vehicles.length === 0) return null;

  const groups = groupVehicles(vehicles).sort((a: any, b: any) => {
    return b[1][sortOrder] > a[1][sortOrder] ? -1 : 1;
  });

  const setModell = (id: any) => {
    const payload = {
      type: "UPDATE_FILTER",
      payload: { modell: [id] },
    };
    dispatchFilter(payload);
    navigate("/catalog/mo/" + id);
  };

  const detail = (id: any) => {
    navigate("/catalog/dt/" + id);
  };

  return (
    <>
      <Header id={modell_gruppe_id} />
      <Box
        sx={{
          display: "grid",
          columnGap: { xs: 1, sm: 2, md: 3 },
          rowGap: { xs: 2, md: 3, lg: 4 },
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(2, 1fr)",
            lg: "repeat(3, 1fr)",
          },
        }}
      >
        {groups.map(([modellId, group]: [string, any]) => {
          const handler = group.vorrat === 1 ? detail : setModell;
          const id = group.vorrat === 1 ? group.id : modellId;
          return (
            <ModelleCard
              key={id}
              data={group}
              onClickHandler={() => handler(id)}
              paperProps={{
                sx: {
                  borderRadius: 1,
                  backgroundColor: "grey.300",
                  ":hover": {
                    borderColor: "grey.800",
                    backgroundColor: "grey.300",
                    cursor: "pointer",
                  },
                },
              }}
            />
          );
        })}
      </Box>
    </>
  );
};

export default Modelle;
