import { createIntl } from "@formatjs/intl";

import { TVehicle } from "types";

import de from "./envkv.json";

const flattenObject = (obj: any): any => {
  let resultObj: any = {};

  for (const i in obj) {
    if (typeof obj[i] === "object" && !Array.isArray(obj[i])) {
      const tempObj = flattenObject(obj[i]);
      for (const j in tempObj) {
        resultObj[i + "." + j] = tempObj[j];
      }
    } else {
      resultObj[i] = obj[i];
    }
  }
  return resultObj;
};

const intl = createIntl({
  locale: "de",
  defaultLocale: "de",
  messages: flattenObject(de),
});

const t = (id: string) => intl.formatMessage({ id });
const n = (
  value: any,
  unit: string | null = null,
  digits: number = 1
): string | undefined => {
  return Number.isNaN(parseInt(value))
    ? undefined
    : intl.formatNumber(value, {
        minimumFractionDigits: digits,
        maximumFractionDigits: digits,
      }) + (!!unit ? ` ${unit}` : "");
};

export const getEnVKV = (vehicle: TVehicle): any => {
  const {
    kraftstoff: { id: kraftstoffId },
    modell,
    fzart,
  } = vehicle;
  if (fzart.id === 4) return null;

  const fields = ["title"];

  const EnVKV = new Map();

  fields.map((field) => EnVKV.set(field, t(field)));

  const information = new Map();
  information
    .set("marke", modell.marke.name)
    .set("handelsbezeichnung", modell.name)
    .set("antriebsart", t(`kraftstoff.${kraftstoffId}.antriebsart`))
    .set("kraftstoff", t(`kraftstoff.${kraftstoffId}.kraftstoff`))
    .set(
      "anderer_energietraeger",
      t(`kraftstoff.${kraftstoffId}.anderer_energietraeger`)
    )
    .set(
      "umweltplakette",
      vehicle.umweltplakette ? vehicle.umweltplakette.name : undefined
    )
    .set("schadstoff", vehicle.schadstoff ? vehicle.schadstoff.name : undefined)
    .set("plugin_hybrid", vehicle.plugin_hybrid)
    .set("hubraum", vehicle.hubraum)
    .set("tsn", vehicle.tsn)
    .set("hsn", vehicle.hsn);

  EnVKV.set("information", information);

  let summary = new Map();

  if (vehicle.wltp_kombiniert)
    summary.set("wltp_kombiniert", {
      label: t("label.wltp_kombiniert"),
      value: n(
        vehicle.wltp_kombiniert,
        t(`kraftstoff.${kraftstoffId}.wltp_kombiniert.unit`)
      ),
    });

  if (vehicle.wltp_stromverbrauch_kombiniert !== null)
    summary.set("wltp_stromverbrauch_kombiniert", {
      label: t("label.wltp_stromverbrauch_kombiniert"),
      value: n(
        vehicle.wltp_stromverbrauch_kombiniert,
        t(`kraftstoff.${kraftstoffId}.wltp_stromverbrauch_kombiniert.unit`)
      ),
    });

  if (vehicle.wltp_kombiniert_entladen !== null)
    summary.set("wltp_kombiniert_entladen", {
      label: t("label.wltp_kombiniert_entladen"),
      value: n(
        vehicle.wltp_kombiniert_entladen,
        t(`kraftstoff.${kraftstoffId}.wltp_kombiniert_entladen.unit`)
      ),
    });

  if ((vehicle.wltp_co2_emissionen !== null) !== null)
    summary.set("wltp_co2_emissionen", {
      label: t("label.wltp_co2_emissionen"),
      value: n(
        vehicle.wltp_co2_emissionen,
        t(`kraftstoff.${kraftstoffId}.wltp_co2_emissionen.unit`)
      ),
    });
  if (vehicle.wltp_co2_emissionen_entladen !== null)
    summary.set("wltp_co2_emissionen_entladen", {
      label: t("label.wltp_co2_emissionen_entladen"),
      value: n(
        vehicle.wltp_co2_emissionen_entladen,
        t(`kraftstoff.${kraftstoffId}.wltp_co2_emissionen_entladen.unit`)
      ),
    });

  if (vehicle.wltp_elektro_reichweite_min !== null)
    summary.set("wltp_elektro_reichweite_min", {
      label: t("label.wltp_elektro_reichweite_min"),
      value: n(
        vehicle.wltp_elektro_reichweite_min,
        t(`kraftstoff.${kraftstoffId}.wltp_elektro_reichweite_min.unit`),
        0
      ),
    });
  if (vehicle.wltp_elektro_reichweite_max !== null)
    summary.set("wltp_elektro_reichweite_max", {
      label: t("label.wltp_elektro_reichweite_max"),
      value: n(
        vehicle.wltp_elektro_reichweite_max,
        t(`kraftstoff.${kraftstoffId}.wltp_elektro_reichweite_max.unit`),
        0
      ),
    });

  if (vehicle.wltp_co2klasse !== null)
    summary.set("wltp_co2klasse", {
      label: t("label.wltp_co2klasse"),
      value: vehicle.wltp_co2klasse || undefined,
    });

  if (vehicle.wltp_co2klasse_entladen !== null)
    summary.set("wltp_co2klasse_entladen", {
      label: t("label.wltp_co2klasse_entladen"),
      value: vehicle.wltp_co2klasse_entladen || undefined,
    });

  // summary.set(
  //   "wltp_stromverbrauch_kombiniert",
  //   n(
  //     vehicle.wltp_stromverbrauch_kombiniert,
  //     t(`kraftstoff.${kraftstoffId}.wltp_stromverbrauch_kombiniert.unit`)
  //   )
  // );
  // summary.set(
  //   "wltp_stromverbrauch_kombiniert_reiner_batteriebetrieb",
  //   n(
  //     vehicle.wltp_stromverbrauch_kombiniert_reiner_batteriebetrieb,
  //     t(
  //       `kraftstoff.${kraftstoffId}.wltp_stromverbrauch_kombiniert_reiner_batteriebetrieb.unit`
  //     )
  //   )
  // );

  // summary.set("wltp_co2_emissionen", {
  //   label: t("label.wltp_co2_emissionen"),
  //   value: n(
  //     vehicle.wltp_co2_emissionen,
  //     t(`kraftstoff.${kraftstoffId}.wltp_co2_emissionen.unit`)
  //   ),
  // });

  // if (vehicle.wltp_co2_emissionen_entladen)
  //   summary.set("wltp_co2_emissionen_entladen", {
  //     label: t("label.wltp_co2_emissionen_entladen"),
  //     value: n(
  //       vehicle.wltp_co2_emissionen_entladen,
  //       t(`kraftstoff.${kraftstoffId}.wltp_co2_emissionen_entladen.unit`)
  //     ),
  //   });

  // summary
  //   .set(
  //     "wltp_elektro_reichweite_max",
  //     n(
  //       vehicle.wltp_elektro_reichweite_max,
  //       t(`kraftstoff.${kraftstoffId}.wltp_elektro_reichweite_max.unit`)
  //     )
  //   )
  //   .set(
  //     "wltp_elektro_reichweite_min",
  //     n(
  //       vehicle.wltp_elektro_reichweite_min,
  //       t(`kraftstoff.${kraftstoffId}.wltp_elektro_reichweite_min.unit`)
  //     )
  //   )
  //   .set(
  //     "wltp_gesamt_reichweite_max",
  //     n(
  //       vehicle.wltp_gesamt_reichweite_max,
  //       t(`kraftstoff.${kraftstoffId}.wltp_gesamt_reichweite_max.unit`)
  //     )
  //   )
  //   .set(
  //     "wltp_gesamt_reichweite_min",
  //     n(
  //       vehicle.wltp_gesamt_reichweite_min,
  //       t(`kraftstoff.${kraftstoffId}.wltp_gesamt_reichweite_min.unit`)
  //     )
  //   );

  EnVKV.set("summary", summary);

  // const wltp_co2klasse = vehicle.wltp_co2klasse;
  // const additional_information = {};
  return EnVKV;
};

/*
    "co2_emissionen": 34,
    "verbrauch_kombiniert": 1.5,
    "verbrauch_land": 6.8,
    "verbrauch_stadt": 1.7,
    "stromverbrauch_kombiniert": 18.3,
    "co2_effizienzklasse": "A+++",

    "umweltplakette": 4, (grün)
    "schadstoff": 6,  (EURO6)
    "plugin_hybrid": true,

    "wltp_co2_emissionen": 32,,
    "wltp_co2klasse": "B",
    "wltp_co2_emissionen_entladen": null,
    "wltp_co2klasse_entladen": "",

    "wltp_kombiniert": null,
    "wltp_langsam": null,
    "wltp_mittel": null,
    "wltp_schnell": null,
    "wltp_sehr_schnell": null,
    "wltp_elektro_reichweite_max": null,
    "wltp_elektro_reichweite_min": null,
    "wltp_gesamt_reichweite_max": null,
    "wltp_gesamt_reichweite_min": null,
    "wltp_stromverbrauch_kombiniert": null,
    "wltp_stromverbrauch_langsam": null,
    "wltp_stromverbrauch_mittel": null,
    "wltp_stromverbrauch_schnell": null,
    "wltp_stromverbrauch_sehr_schnell": null,
    "wltp_stromverbrauch_kombiniert_reiner_batteriebetrieb": null,
    "wltp_stromverbrauch_langsam_reiner_batteriebetrieb": null,
    "wltp_stromverbrauch_mittel_reiner_batteriebetrieb": null,
    "wltp_stromverbrauch_schnell_reiner_batteriebetrieb": null,
    "wltp_stromverbrauch_sehr_schnell_reiner_batteriebetrieb": null,
    "wltp_kombiniert_entladen": null,
    "wltp_langsam_entladen": null,
    "wltp_mittel_entladen": null,
    "wltp_schnell_entladen": null,
    "wltp_sehr_schnell_entladen": null
    */

export const stringify = (_map: any): any => {
  const result: string[] = [];
  _map.forEach((value: { label: string; value: string }, key: string) => {
    if (!value.value) return;
    result.push(`${value.label}: ${value.value}`);
  });
  return result.join("; ");
};
