import React from "react";
import { useSearchParams } from "react-router-dom";

import Box from "@mui/material/Box";

import { CatalogStore, FilterStore } from "stores";

import {
  AufbauFilter,
  AusstattungFilter,
  FarbeAussenFilter,
  FinanceFilter,
  FzartFilter,
  GetriebeFilter,
  ErstzulassungFilter,
  KilometerFilter,
  KraftstoffFilter,
  LocationFilter,
  MarkeModellGruppeFilter,
  LeistungFilter,
  TuerenFilter,
  SitzeFilter,
} from "./components/filters";

interface iFilter {
  disableMake: boolean;
}

const Filter: React.FC<iFilter> = ({
  disableMake = false,
}): JSX.Element | null => {
  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        height: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
        paddingRight: "1rem",
      }}
    >
      <FinanceFilter />
      <MarkeModellGruppeFilter disabled={disableMake} />
      <LocationFilter />
      <KraftstoffFilter />
      <LeistungFilter />
      <AufbauFilter />
      <FzartFilter />
      <ErstzulassungFilter />
      <KilometerFilter />
      <AusstattungFilter />
      <GetriebeFilter />
      <FarbeAussenFilter />
      {process.env.REACT_APP_VEHICLE_TYPE !== "krad" && <TuerenFilter />}
      {process.env.REACT_APP_VEHICLE_TYPE !== "krad" && <SitzeFilter />}
      <Parameterizer />
    </Box>
  );
};

export default Filter;

const Parameterizer = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    state: { filter },
  } = React.useContext(FilterStore);
  const {
    state: { entities },
  } = React.useContext(CatalogStore);

  const checkAndAdd = (which: string, filter: any, paramsMap: any): any => {
    if (filter[which] && filter[which].length > 0)
      paramsMap.set(which, filter[which]);
    return paramsMap;
  };
  React.useEffect(() => {}, [searchParams]);
  React.useEffect(() => {
    const URLFilters = new URLSearchParams();

    [
      "marke",
      "modell",
      "modell_gruppe",
      "location",
      "kraftstoff",
      "leistung",
      "aufbau",
      "fzart",
      "erstzulassung",
      "kilometer",
      "getriebe",
      "ausstattung",
      "farbe_aussen",
      "tueren",
      "sitze",
    ].map((which: string) => checkAndAdd(which, filter, URLFilters));
    setSearchParams(URLFilters);
  }, [filter]);
  return <></>;
};
