import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { string, object, boolean } from "yup";
import { FormattedMessage, useIntl } from "react-intl";
import Markdown from "react-markdown";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";

import Send from "@mui/icons-material/Send";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { TReactHookForm, TVehicle } from "types";

import {
  ReactHookFormIntPhone,
  ReactHookFormSelect,
  ReactHookFormSwitch,
  ReactHookFormText,
  ReactHookFormCheckbox,
} from "components";

enum ERateType {
  cashPurchase = "CASHPURCHASE",
  classicCarLoan = "CLASSICCARLOAN",
  balloonFinancing = "BALLOONFINANCING",
  leasingMileage = "LEASINGMILEAGE",
}
type TRateType = keyof typeof ERateType;

enum ETopic {
  vehicle = "VEHICLE",
  model = "MODEL",
  notSpecific = "NOTSPECIFIC",
}
type TTopic = keyof typeof ETopic;

interface IContact {
  contact?: {
    name?: string;
    byMail?: boolean;
    email?: string;
    byPhone?: boolean;
    phone?: string;
    getOffer?: boolean;
    finance?: { rateType?: TRateType; topic?: TTopic };
    testdrive?: boolean;
    message?: string;
    agreement?: boolean;
  };
  vehicle: TVehicle;
  configuration?: any;
  paperProps?: any;
}

const Contact: React.FC<IContact> = ({
  contact,
  vehicle,
  configuration = {
    inquiry: {
      email: true,
      phone: true,
    },
  },
  paperProps = { sx: { p: 2 } },
}) => {
  const {
    register,
    control,
    formState: { errors, isValid },
    getValues,
    setValue,
    trigger,
    watch,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(ValidationSchema),
    defaultValues: {
      name: "",
      byMail: !!configuration.inquiry?.email,
      email: "",
      byPhone: !!configuration.inquiry?.phone,
      phone: "",
      getOffer: false,
      finance: { rateType: "cashPurchase", topic: "vehicle" },
      testdrive: false,
      message: "",
      agreement: false,
      ...contact,
    },
  });

  const [isLoading, setIsLoading] = React.useState(false);
  const [isLoaded, setIsLoaded] = React.useState(false);
  const { formatMessage: t } = useIntl();

  React.useEffect(() => {
    trigger();
  }, [trigger]);

  const submit = async () => {
    const url = `https://service.carbando.eu/contact/inquiry`;
    const payload = getValues();
    const shopId = process.env.REACT_APP_SHOP_ID;
    const { wgnr } = vehicle;
    const rateType = t({
      id: `forms.contact.finance.rateType.options.${payload.finance.rateType}`,
    });
    const topic = t({
      id: `forms.contact.finance.topic.options.${payload.finance.topic}`,
    });
    const dealerId = vehicle.location
      ? vehicle.location.standort_code ||
        t({
          id: `location.${vehicle.location.id}.standort_code`,
          defaultMessage: "",
        })
      : null;
    const body = {
      ...payload,
      rateType,
      topic,
      shopId,
      dealerId,
      vehicleId: wgnr,
    };
    const options = {
      method: "POST",
      body: JSON.stringify(body),
    };

    setIsLoading(true);
    const response = await fetch(url, options);
    setIsLoading(false);
    setIsLoaded(true);
    if (response.ok) {
      const result = await response.json();
      console.log(JSON.parse(result.body));
    } else {
      alert("HTTP-Error: " + response.status);
    }
  };

  const watchedValues = {
    byMail: watch("byMail"),
    byPhone: watch("byPhone"),
    getOffer: watch("getOffer"),
    testdrive: watch("testdrive"),
    message: watch("message"),
    name: watch("name"),
    agreement: watch("agreement"),
  };

  const step_3_isValid = () => {
    return (
      watchedValues.testdrive ||
      watchedValues.getOffer ||
      watchedValues.message.length > 0
    );
  };

  const formIsValid = () => {
    return step_3_isValid() && isValid;
  };

  return (
    <Paper
      id="Offer"
      data-testid="pages-detail-component-contact"
      variant="outlined"
      {...paperProps}
    >
      <Typography variant="h5" sx={{ mb: 2 }} data-testid="typography-title">
        <FormattedMessage
          id="pages.detail.offer.title"
          defaultMessage="Fahrzeug anfragen"
        />
      </Typography>
      <ContactForm
        {...{
          register,
          control,
          errors,
          setValue,
          trigger,
        }}
        {...watchedValues}
        errors={errors}
        disabled={isLoading || isLoaded}
        configuration={configuration}
      />
      <Step
        color={watchedValues.agreement ? "green" : "red"}
        step={4}
        title="Absenden"
      >
        <Grid container sx={{ mt: 2 }}>
          <Grid
            item
            xs={12}
            sx={{
              p: { xs: 1, md: 2 },
              a: {
                color: "black",
                textDecoration: "none",
                fontWeight: "bold",
              },
              "a:hover": {
                textDecoration: "underline",
              },
            }}
          >
            <ReactHookFormCheckbox
              control={control}
              name="agreement"
              disabled={isLoading || isLoaded}
              label={
                <Markdown>
                  {t(
                    {
                      id: "pages.detail.offer.agreement",
                      defaultMessage:
                        'Ich stimme den [allgemeinen Geschäftsbedingungen](https://cdn.carbando.eu/{customer}/AGB.pdf "allgemeinen Geschäftsbedingungen") und der [Datenschutzerklärung](https://cdn.carbando.de/{customer}/Datenschutz.pdf "Datenschutzerklärung") zu und erkläre mich einverstanden, mit der Verarbeitung, Nutzung und der Weiterleitung meiner Daten zum Zwecke der Kontaktaufnahme. *',
                    },
                    { customer: process.env.REACT_APP_CUSTOMER || "carbando" }
                  )}
                </Markdown>
              }
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            sx={{ textAlign: "right", display: "grid", alignItems: "center" }}
          >
            <Stack direction="row" spacing={3} sx={{ justifySelf: "center" }}>
              <Avatar
                sx={{
                  backgroundColor: !watchedValues.name.length ? "red" : "green",
                }}
              >
                1
              </Avatar>
              <Avatar
                sx={{
                  backgroundColor: !(
                    watchedValues.byMail || watchedValues.byPhone
                  )
                    ? "red"
                    : (watchedValues.byMail && errors?.email) ||
                        (watchedValues.byPhone && errors?.phone)
                      ? "red"
                      : "green",
                }}
              >
                2
              </Avatar>
              <Avatar
                sx={{
                  backgroundColor: !(
                    watchedValues.testdrive ||
                    watchedValues.getOffer ||
                    watchedValues.message.length
                  )
                    ? "red"
                    : "green",
                }}
              >
                3
              </Avatar>
              <Avatar
                sx={{
                  backgroundColor: watchedValues.agreement ? "green" : "red",
                }}
              >
                4
              </Avatar>
            </Stack>
          </Grid>
          <Grid item md={6} xs={12} sx={{ textAlign: "center" }}>
            <Button
              data-testid="button-submit"
              disabled={!formIsValid() || isLoading || isLoaded}
              color={isLoaded ? "success" : "primary"}
              variant="contained"
              sx={{ mt: 2, pr: 5 }}
              onClick={() => submit()}
            >
              {!(isLoading || isLoaded) && (
                <>
                  <FormattedMessage
                    id="pages.detail.offer.submit.isValid"
                    defaultMessage="Absenden"
                  />
                  <Send
                    fontSize="small"
                    sx={{ color: "white", position: "absolute", right: 10 }}
                  />
                </>
              )}
              {isLoading && (
                <>
                  <FormattedMessage
                    id="pages.detail.offer.submit.isLoading"
                    defaultMessage="Senden..."
                  />
                  <CircularProgress
                    color="inherit"
                    size={23}
                    sx={{ color: "white", position: "absolute", right: 10 }}
                  />
                </>
              )}
              {isLoaded && (
                <>
                  <FormattedMessage
                    id="pages.detail.offer.submit.isLoaded"
                    defaultMessage="Gesendet"
                  />
                  <ThumbUpIcon
                    fontSize="small"
                    sx={{ color: "green", position: "absolute", right: 10 }}
                  />
                </>
              )}
            </Button>
          </Grid>
        </Grid>
      </Step>
    </Paper>
  );
};

interface IContactForm extends TReactHookForm {
  byMail: boolean;
  byPhone: boolean;
  testdrive: boolean;
  getOffer: boolean;
  message: string;
  name: string;
  disabled?: boolean;
  configuration?: any;
  trigger?: any;
}

interface IStep {
  step: number;
  title: string;
  color?: string;
  sx?: any;
}
const Step: React.FC<IStep> = ({
  step = 1,
  sx = {},
  title = "",
  children,
  color = "grey",
}) => (
  <Paper
    sx={{ p: 2, mt: 1, ...sx }}
    variant="outlined"
    data-testid={`step-${step}`}
  >
    <Stack direction="row" spacing={2}>
      <Avatar
        variant="rounded"
        sx={{
          backgroundColor: color,
          fontFamily: "monospace",
          width: "2rem",
          height: "2rem",
        }}
      >
        {step}
      </Avatar>
      <Typography variant="h6">{title}</Typography>
    </Stack>
    <Divider sx={{ my: 2 }} />
    {children}
  </Paper>
);

const ContactForm = ({
  register,
  control,
  errors,
  setValue = () => null,
  byMail,
  byPhone,
  testdrive,
  getOffer,
  message,
  name,
  disabled = false,
  configuration = {},
  trigger = () => null,
}: IContactForm): JSX.Element => {
  const activate = (which: string) => {
    setValue(which, true);
  };

  return (
    <form noValidate autoComplete="off">
      <input
        type="hidden"
        name="byPhone"
        value={byPhone}
        {...register("byPhone")}
      />
      <input
        type="hidden"
        name="byMail"
        value={byMail}
        {...register("byMail")}
      />
      <input
        type="hidden"
        name="getOffer"
        value={getOffer}
        {...register("getOffer")}
      />

      <Step
        color={name.length ? "green" : "red"}
        step={1}
        title="Bitte nennen Sie Ihren Namen"
      >
        <ReactHookFormText
          register={register}
          name="name"
          label="Name"
          required={true}
          error={!!errors?.name}
          props={{ fullWidth: true }}
          disabled={disabled}
        />
      </Step>
      <Step
        step={2}
        color={
          (byMail && errors?.email) || (byPhone && errors?.phone)
            ? "red"
            : "green"
        }
        title="Bitte übermitteln Sie uns Ihre Kontaktdaten."
      >
        <Box sx={{ position: "relative" }}>
          <Grid container>
            {!configuration.inquiry?.email && (
              <Grid item xs={12} sm={6}>
                <ReactHookFormSwitch
                  control={control}
                  name="byMail"
                  label="Bitte kontaktieren Sie mich per E-Mail"
                  disabled={disabled}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <ReactHookFormText
                register={register}
                name="email"
                label="Email"
                required={configuration.inquiry?.email}
                disabled={!byMail || disabled}
                type="email"
                error={byMail && errors?.email}
                props={{
                  fullWidth: true,
                  autoFocus: true,
                }}
                inputProps={{ autoFocus: true }}
              />
            </Grid>
          </Grid>
          {!byMail && <Trigger onClick={() => activate("byMail")} />}
        </Box>
        <Box sx={{ my: 3, position: "relative" }}>
          <Grid container>
            {!configuration.inquiry?.phone && (
              <Grid item xs={12} sm={6}>
                <ReactHookFormSwitch
                  control={control}
                  name="byPhone"
                  label="Bitte rufen Sie mich zurück"
                  disabled={disabled}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <ReactHookFormIntPhone
                control={control}
                name="phone"
                required={configuration.inquiry?.phone}
                countryDisabled
                error={byPhone && errors?.phone}
                disabled={!byPhone || disabled}
              />
            </Grid>
          </Grid>
          {!byPhone && <Trigger onClick={() => activate("byPhone")} />}
        </Box>
      </Step>
      <Step
        color={!(testdrive || getOffer || message.length) ? "red" : "green"}
        step={3}
        title="Was können wir für sie tun?"
      >
        <Box sx={{ mb: 3, position: "relative" }}>
          <ReactHookFormSwitch
            control={control}
            name="testdrive"
            label="Ich interessiere ich für eine Probefahrt"
            disabled={disabled}
          />
          {!testdrive && <Trigger onClick={() => activate("testdrive")} />}
        </Box>
        <Box sx={{ mb: 2, position: "relative" }}>
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
              <ReactHookFormSwitch
                control={control}
                name="getOffer"
                label="Bitte unterbreiten Sie mir ein unverbindliches Angebot"
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <ReactHookFormSelect
                    disabled={!getOffer || disabled}
                    control={control}
                    prefix="finance."
                    name="rateType"
                    sort={false}
                    defaultValue="leasingResidualValue"
                    options={[
                      "cashPurchase",
                      "classicCarLoan",
                      "balloonFinancing",
                      "leasingMileage",
                    ]}
                    labelPrefix="contact.finance.rateType"
                    error={errors?.finance?.rateType.type}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ReactHookFormSelect
                    disabled={!getOffer || disabled}
                    control={control}
                    prefix="finance."
                    name="topic"
                    sort={false}
                    defaultValue="vehicle"
                    options={["vehicle", "model", "notSpecific"]}
                    labelPrefix="contact.finance.topic"
                    error={errors?.finance?.topic.type}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!getOffer && <Trigger onClick={() => activate("getOffer")} />}
        </Box>
        <ReactHookFormText
          register={register}
          name="message"
          label="Nachricht"
          disabled={disabled}
          required={false}
          // error={errors?.message?.type}
          props={{ fullWidth: true, multiline: true, rows: 2 }}
        />
      </Step>
    </form>
  );
};

interface ITrigger {
  onClick: any;
}
const Trigger: React.FC<ITrigger> = ({ onClick }): JSX.Element => (
  <Box
    onClick={() => onClick()}
    sx={{
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "red",
      opacity: 0,
    }}
  />
);

const ValidationSchema = object().shape({
  name: string().required(),
  email: string().when("byMail", {
    is: true,
    otherwise: (schema) => schema,
    then: (schema) => string().email().required(),
  }),
  phone: string().when("byPhone", {
    is: true,
    otherwise: (schema) => schema,
    then: (schema) =>
      string()
        .trim()
        .matches(
          /^\+491(51|60|70|71|75|52|62|72|73|74|57|63|77|78|59|76|79)\d{7}\d*$/
        )
        .min(1)
        .required(),
  }),
  // oneOfContacts: string().when(["byMail", "byPhone"], {
  //   is: (byMail: boolean, byPhone: boolean) => byMail || byPhone,
  //   then: (schema) => schema,
  //   otherwise: (schema) => string().trim().min(1),
  // }),

  byMail: boolean(),
  byPhone: boolean(),
  testDrive: boolean(),
  getOffer: boolean(),
  agreement: boolean().oneOf([true], "Field must be checked"),
});

export default Contact;
