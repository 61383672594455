import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { App } from "containers";
import { Intl } from "intl";
import { Theme } from "themes";
import {
  CatalogProvider,
  CartProvider,
  FilterProvider,
  VehicleProvider,
  CheckoutStoreProvider,
  CookieProvider,
  Configuration,
} from "stores";
import reportWebVitals from "./reportWebVitals";

import { HelmetProvider } from "react-helmet-async";

const container = document.getElementById("carbando-root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Configuration>
      <Theme>
        <BrowserRouter>
          <CookieProvider config={{ type: "prod" }}>
            <CatalogProvider config={{ type: "prod" }}>
              <CartProvider config={{ type: "prod" }}>
                <CheckoutStoreProvider config={{ type: "prod" }}>
                  <FilterProvider config={{ type: "prod" }}>
                    <VehicleProvider>
                      <HelmetProvider>
                        <Intl>
                          <App />
                        </Intl>
                      </HelmetProvider>
                    </VehicleProvider>
                  </FilterProvider>
                </CheckoutStoreProvider>
              </CartProvider>
            </CatalogProvider>
          </CookieProvider>
        </BrowserRouter>
      </Theme>
    </Configuration>
  </React.StrictMode>
);

reportWebVitals();
