import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Map, Marker, ZoomControl } from "pigeon-maps";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";

import { Tupel } from "components";

import { TVehicle, TLocation } from "types";

interface IDealer {
  paperProps?: any;
  vehicle: TVehicle;
}

const Dealer: React.FC<IDealer> = ({
  paperProps = { sx: { p: 2 } },
  vehicle,
}): JSX.Element | null => {
  const [coordinates, setCoordinates] = React.useState<any>();
  const { formatMessage: t } = useIntl();
  React.useEffect(() => {
    const loadCoordinates = async (payload: any) => {
      const url = "https://service.carbando.eu/locate";
      const options = { method: "POST", body: JSON.stringify(payload) };
      const response = await fetch(url, options);
      const data = await response.json();
      if (data.lat && data.lon) setCoordinates(data);
    };
    if (!vehicle.location) return;
    const { id, lat, lon, zip } = vehicle.location;
    if (lat && lon) {
      setCoordinates({ lat, lon });
    } else if (zip) {
      const _lat = parseFloat(
        t({
          id: `location.${id}.lat`,
          defaultMessage: "",
        })
      );
      const _lon = parseFloat(
        t({
          id: `location.${id}.lon`,
          defaultMessage: "",
        })
      );
      if (!(_lat && _lon)) {
        loadCoordinates(vehicle.location);
      } else {
        setCoordinates({ lat: _lat, lon: _lon });
      }
    }
  }, [vehicle]);

  if (!(vehicle?.location && coordinates)) return null;
  const { id, name, address, zip, city, standort_code }: TLocation =
    vehicle.location;

  const standort_code_intl = t({
    id: `location.${id}.standort_code`,
    defaultMessage: "nich bekannt",
  });

  // https://www.google.com/maps/place/49.46800006494457,17.11514008755796
  return (
    <Paper variant="outlined" {...paperProps}>
      <Typography variant="h5">
        <FormattedMessage id="common.dealer" defaultMessage="Händler" />
      </Typography>
      <Tupel label="Standort" text={name} />
      <Tupel label="Straße" text={address} />
      {/* <Tupel label="lat" float={coordinates.lat} />
      <Tupel label="lon" float={coordinates.lon} /> */}
      <Tupel label="Ort" text={`${zip} ${city}`} />
      {/* <Tupel
        label="Standort Code"
        text={`${standort_code || standort_code_intl}`}
      /> */}
      {coordinates && (
        <Box sx={{ height: "300px" }}>
          <Map
            defaultCenter={[coordinates.lat, coordinates.lon]}
            defaultZoom={12}
          >
            <ZoomControl />
            <Marker
              width={30}
              color="green"
              anchor={[coordinates.lat, coordinates.lon]}
            />
          </Map>
        </Box>
      )}
    </Paper>
  );
};

export default Dealer;
